import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';

import { BASE_URL } from './Constants.js';

const EditDIEM = () => {
	const [formData, setFormData] = useState({
		diem: '',
		city_class: '',
		hotel: '',
		grade: '',
	});

	const navigate = useNavigate();
	const [loading, setLoading] = useState(true); // Set initial loading state to true
	const [error, setError] = useState('');
	const location = useLocation();
	const { city_class = '', grade = '' } = location.state || {}; // Get data from location
	const { id } = useParams();
	const token = localStorage.getItem('authToken') || null;
	// Handle input changes
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	// Handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		const formDataToSubmit = new FormData();
		Object.keys(formData).forEach((key) => {
			formDataToSubmit.append(key, formData[key]);
		});

		try {
			const response = await fetch(`${BASE_URL}api/editDiem`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			const result = await response.json();

			if (result.status) {
				toast.success('DIEM successfully updated');
				navigate('/manage_diem');
			} else {
				toast.error(
					result.message || 'An error occurred while updating the DIEM.',
				);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			toast.error('Failed to submit form');
		}
	};
	useEffect(() => {
		console.log('Location State:', location.state);
		console.log('City Class:', city_class);
		console.log('Grade:', grade);

		if (!city_class || !grade) {
			toast.error('Missing required parameters. Redirecting...');
			navigate('/manage_diem'); // Redirect if parameters are missing
		}
	}, [location.state, city_class, grade, navigate]);

	// Fetch DIEM details
	useEffect(() => {
		const fetchDiem = async () => {
			setLoading(true);
			try {
				const formData = new URLSearchParams();
				formData.append('city_class', city_class);
				formData.append('grade', grade);

				const response = await fetch(`${BASE_URL}api/getDiemDetails`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					body: formData.toString(),
				});

				const result = await response.json();
				if (result && result.data) {
					setFormData({
						grade: result.data.grade || '',
						city_class: result.data.city_class || '',
						hotel: result.data.hotel || '',
						diem: result.data.diem || '',
					});
				} else {
					setError('Failed to fetch DIEM details.');
				}
			} catch (error) {
				console.error('Fetch error:', error);
				setError('Failed to get DIEM details.');
			} finally {
				setLoading(false);
			}
		};

		// Fetch only if city_class and grade are valid
		if (city_class && grade) {
			fetchDiem();
		}
	}, [city_class, grade, navigate]);

	return (
		<>
			<div className='hold-transition sidebar-mini layout-fixed'>
				<div className='wrapper'>
					<Header />
					<Sidebar />

					<div className='content-wrapper'>
						<div className='content-header'>
							<div className='container-fluid'>
								<div className='row mb-2'>
									<div className='col-sm-6'>
										<h1 className='m-0'>Edit DIEM</h1>
									</div>
									<div className='col-sm-6'>
										<ol className='breadcrumb float-sm-right'>
											<li className='breadcrumb-item'>
												<a href='#'>Home</a>
											</li>
											<li className='breadcrumb-item active'>Edit DIEM</li>
										</ol>
									</div>
								</div>
							</div>
						</div>

						<section className='col-lg-12'>
							<div className='card' style={{ borderTop: '3px solid #00c0ef' }}>
								<div className='box box-info'>
									<div className='card-header'>
										<h3 className='card-title'>Edit DIEM</h3>
									</div>

									<div className='card-body'>
										{loading ? ( // Show loader while data is being fetched
											<div>Loading...</div>
										) : (
											<form
												onSubmit={handleSubmit}
												method='post'
												acceptCharset='utf-8'
											>
												<div className='box-body'>
													<div className='row'>
														<div className='col-md-3'>
															<div className='form-group'>
																<label htmlFor='city_class'>
																	City Class{' '}
																	<span style={{ color: 'red' }}>*</span>
																</label>
																<select
																	name='city_class'
																	value={formData.city_class || ''}
																	onChange={handleChange}
																	className='form-control'
																>
																	<option value=''>Select Class</option>
																	<option value='A'>A</option>
																	<option value='B'>B</option>
																	<option value='C'>C</option>
																	<option value='D'>D</option>
																</select>
															</div>
														</div>

														<div className='col-md-3'>
															<div className='form-group'>
																<label htmlFor='grade'>
																	Band <span style={{ color: 'red' }}>*</span>
																</label>
																<select
																	name='grade'
																	value={formData.grade || ''}
																	onChange={handleChange}
																	className='form-control'
																>
																	<option value=''>Select Band</option>
																	<option value='A'>A</option>
																	<option value='B'>B</option>
																	<option value='C'>C</option>
																	<option value='D'>D</option>
																	<option value='E'>E</option>
																	<option value='F'>F</option>
																</select>
															</div>
														</div>

														<div className='col-md-3'>
															<div className='form-group'>
																<label htmlFor='diem'>
																	DIEM <span style={{ color: 'red' }}>*</span>
																</label>
																<input
																	type='text'
																	name='diem'
																	value={formData.diem || ''}
																	onChange={handleChange}
																	className='form-control'
																	placeholder='DIEM'
																/>
															</div>
														</div>

														<div className='col-md-3'>
															<div className='form-group'>
																<label htmlFor='hotel'>
																	Hotel <span style={{ color: 'red' }}>*</span>
																</label>
																<input
																	type='text'
																	name='hotel'
																	value={formData.hotel || ''}
																	onChange={handleChange}
																	className='form-control'
																	placeholder='Hotel / Lodge Charges'
																/>
															</div>
														</div>
													</div>
												</div>

												<div className='box-footer'>
													<button
														type='submit'
														className='btn btn-success pull-right'
													>
														Submit
													</button>
												</div>
											</form>
										)}
									</div>
								</div>
							</div>
						</section>
					</div>

					<Footer />
					<aside className='control-sidebar control-sidebar-dark'></aside>
				</div>

				<ToastContainer />
			</div>
		</>
	);
};

export default EditDIEM;
