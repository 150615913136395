import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from './vdc_logo.png';
import './Sidebar.css';

const Sidebar = () => {
    const [userType, setUserType] = useState('');
    const [formData, setFormData] = useState({
        emp_code: ''
    });
    const [responseMessage, setResponseMessage] = useState('');
    const [openDropdowns, setOpenDropdowns] = useState({});
    const [isAdmin, setIsAdmin] = useState(false); // New state for admin check

    const toggleDropdown = (id) => {
        setOpenDropdowns((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`https://travel.vdcapp.in/backend/api/profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setFormData({
                        emp_code: result.profile.emp_code || '',
                        mail_id: result.profile.mail_id || '',
                        password: '' // Do not display the password
                    });
                    setIsAdmin(result.profile.is_admin === 'Y'); // Check if admin

                    // Store expiration time in localStorage
                    const expirationTime = Date.now() + 3600 * 1000; // 1 hour from now
                    localStorage.setItem('expirationTime', expirationTime);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData(); // Call the function to fetch profile data
    }, []); // Ensure this runs once on component mount

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/dashboard" className="brand-link">
                <img
                    src={logo}
                    style={{ width: '30px', height: '30px', opacity: 0.8 }}
                    className="brand-image img-circle elevation-3"
                    alt="Brand Logo"
                />
                <span className="brand-text font-weight-light">Travel App</span>
            </Link>

            <div className="sidebar">
                <div className="form-inline">
                    <div className="input-group" data-widget="sidebar-search">
                        <input
                            className="form-control form-control-sidebar"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                        />
                        <div className="input-group-append">
                            <button className="btn btn-sidebar">
                                <i className="fas fa-search fa-fw"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column">
                        <li className="nav-item">
                            <Link to="/dashboard" className="nav-link">
                                <i className="fas fa-chart-line nav-icon"></i>
                                <p>Dashboard</p>
                            </Link>
                        </li>
                        {isAdmin && (<li className="nav-item">
                            <Link to="/manage_employee" className="nav-link">
                                <i className="fas fa-chart-line nav-icon"></i>
                                <p>Employee</p>
                            </Link>
                        </li>)}

                        {isAdmin && ( // Conditionally render Settings if isAdmin is true
                            <li className="nav-item">
                                <a
                                    href="#"
                                    className="nav-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleDropdown('settings');
                                    }}
                                >
                                    <i className="fas fa-cogs nav-icon"></i>
                                    <p>
                                        Settings
                                        <i className={`right fas fa-angle-left ${openDropdowns['settings'] ? 'rotate' : ''}`}></i>
                                    </p>
                                </a>
                                <ul className={`nav nav-treeview ${openDropdowns['settings'] ? 'd-block' : 'd-none'}`}>
                                    <li className='nav-item'>
                                        <Link to='/manage_states' className='nav-link'>
                                            <i className='far fa-circle nav-icon'></i>
                                            <p>Manage States</p>
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to='/manage_cities' className='nav-link'>
                                            <i className='far fa-circle nav-icon'></i>
                                            <p>Manage Cities</p>
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to='/manage_diem' className='nav-link'>
                                            <i className='far fa-circle nav-icon'></i>
                                            <p>DIEM</p>
                                        </Link>
                                    </li>
                                    {/* <li className='nav-item'>
                                        <Link to='/manage_bankinfo' className='nav-link'>
                                            <i className='far fa-circle nav-icon'></i>
                                            <p>Manage Bankinfo</p>
                                        </Link>
                                    </li> */}
                                </ul>
                            </li>
                        )}

                        <li className="nav-item">
                            <a
                                href="#"
                                className="nav-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleDropdown('advance');
                                }}
                            >
                                <i className="fas fa-cogs nav-icon"></i>
                                <p>
                                    Advance Form
                                    <i className={`right fas fa-angle-left ${openDropdowns['advance'] ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            <ul className={`nav nav-treeview ${openDropdowns['advance'] ? 'd-block' : 'd-none'}`}>
                                <li className="nav-item">
                                    <Link to="/own_advance" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Tour Advance Form</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/manage_advance" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Reporting Manager Approval</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/finance_form" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Finance Approval</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a
                                href="#"
                                className="nav-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleDropdown('settlement');
                                }}
                            >
                                <i className="fas fa-cogs nav-icon"></i>
                                <p>
                                    Settlement Form
                                    <i className={`right fas fa-angle-left ${openDropdowns['settlement'] ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            <ul className={`nav nav-treeview ${openDropdowns['settlement'] ? 'd-block' : 'd-none'}`}>
                                <li className="nav-item">
                                    <Link to="/own_settlement" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Tour Settlement Form</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/manage_settlement" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Reporting Manager Approval</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/audit_form" className="nav-link">
                                        <i className="fas fa-file-alt nav-icon"></i>
                                        <p>Audit Approval</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/finance_settlement_form" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Finance Approval</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a
                                href="#"
                                className="nav-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleDropdown('traveldesk');
                                }}
                            >
                                <i className="fas fa-cogs nav-icon"></i>
                                <p>
                                    Travel & Hotel
                                    <i className={`right fas fa-angle-left ${openDropdowns['traveldesk'] ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            <ul className={`nav nav-treeview ${openDropdowns['traveldesk'] ? 'd-block' : 'd-none'}`}>
                                <li className='nav-item'>
                                    <Link to='/manage_travel_desk' className='nav-link'>
                                        <i className='far fa-circle nav-icon'></i>
                                        <p>Manage Travel Desk</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/manage_hotel_info" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Manage Hotel Info</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default Sidebar;
