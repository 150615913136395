// MyDataTable.js
import React from 'react';
import DataTable from 'react-data-table-component';

// Define columns
const columns = [
  {
    name: 'Name',
    selector: row => row.name,
    sortable: true,
  },
  {
    name: 'Position',
    selector: row => row.position,
    sortable: true,
  },
  {
    name: 'Office',
    selector: row => row.office,
    sortable: true,
  },
  {
    name: 'Age',
    selector: row => row.age,
    sortable: true,
  },
  {
    name: 'Start Date',
    selector: row => row.startDate,
    sortable: true,
  },
  {
    name: 'Salary',
    selector: row => row.salary,
    sortable: true,
  },
];

// Sample data
const data = [
  { name: 'Tiger Nixon', position: 'System Architect', office: 'Edinburgh', age: 61, startDate: '2011/04/25', salary: '$320,800' },
  { name: 'Garrett Winters', position: 'Accountant', office: 'Tokyo', age: 63, startDate: '2011/07/25', salary: '$170,750' },
  { name: 'Ashton Cox', position: 'Junior Technical Author', office: 'San Francisco', age: 66, startDate: '2009/01/12', salary: '$86,000' },
  // Add more data as needed
];

const MyDataTable = () => {
  return (
    <div>
      <h1>Employee Data</h1>
      <DataTable
        title="Employee Data"
        columns={columns}
        data={data}
        pagination
        highlightOnHover
        selectableRows
        selectableRowsHighlight
      />
    </div>
  );
};

export default MyDataTable;
