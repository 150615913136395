import React from 'react';

const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
    const range = 2; // Number of page numbers to show around the current page

    const getPageNumbers = () => {
        const numbers = [];
        for (let i = 1; i <= totalPages; i++) {
            if (i === 1 || i === totalPages || (i >= currentPage - range && i <= currentPage + range)) {
                numbers.push(i);
            } else if (i === currentPage - range - 1 || i === currentPage + range + 1) {
                numbers.push('...');
            }
        }
        return numbers;
    };

    const pageNumbers = getPageNumbers();

    return (
        <nav style={{float: 'right'}}>
            <ul className="pagination">
                <li className="page-item">
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        &laquo; Previous
                    </button>
                </li>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                        {number === '...' ? (
                            <span className="page-link">...</span>
                        ) : (
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(number)}
                            >
                                {number}
                            </button>
                        )}
                    </li>
                ))}
                <li className="page-item">
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Next &raquo;
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
