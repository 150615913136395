import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; // Import default CSS for Toastify
import './CustomToastStyles.css'; // Import custom styles
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import './AddSettlement.css';
import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const EditSettlementForm = () => {
    const { id } = useParams();
    const [EmpCode, setEmpCode] = useState('');
    const [RptMgr, setRptMngr] = useState('');
    const [localConveyanceTotals, setLocalConveyanceTotals] = useState({});
    const [items, setItems] = useState([]);
    const [rows1, setRows1] = useState([]);
    const [rows2, setRows2] = useState([]);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        emp_code: '',
        grade: '',
        dept_name: '',
        designation_name: '',
        report_mngr: '',
        password: '', // Do not display the password,
        travel_from_date: '', // Ensure this is initialized
    travel_to_date: '',   // Ensure this is initialized
    days: '',
    audit_status : '',
    finance_approved_amount: '',
    finance_remarks: '',
    account_status: '',
    audit_approved_amount: '',
    audit_remarks: '',
    audit_manager: '',
    accounts_manager: '',
    manager_approved_amount: '',
    utr: '',
    utr_date: '',
    created_on: '',
    manager_approved_date: '',
    audit_approved_date : '',
    finance_approved_date : '',
    });
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();

    const validateFormData = () => {
        const { emp_code } = formData;
        if (!emp_code) {
            toast.error('All fields are required');
            return false;
        }
        return true;
    };
    

   
    const updateLocalConveyanceTotals = () => {
        const totals = {};
        transportRows.forEach((row) => {
            if (row.travelDate) {
                totals[row.travelDate] = (totals[row.travelDate] || 0) + (parseFloat(row.travelAmount) || 0);
            }
        });
        setLocalConveyanceTotals(totals);
    };
    const handleSubmit = async (e) => {
        
        e.preventDefault();

        if (!validateFormData()) return;

        

        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== '') {
                formDataToSubmit.append(key, formData[key]);
            }
        });
        const dataToSubmit = {
            ...formData
        };
        try {
            const token = localStorage.getItem('authToken');
console.log("check tokens",token);
            const response = await fetch(`${BASE_URL}api/updateSettlementForm/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSubmit),
            });

            const result = await response.json();
                console.log("advanceformdata", result.data);
            if (result.status) {
                toast.success('Travel Advance Form successfully added');
                setFormData({
                    comp_name: '',
                    emp_code: ''
                });
                navigate('/own_settlement');
            } else {
                toast.error(result.message || 'An error occurred while adding the travel advance form.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
				console.log("token12",token);
                const response = await fetch(`${BASE_URL}api/getsettlement?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
						 'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
				
                console.log("Settlement data:", result);

                if (result.status) {
                    setEmpCode(result.settlement.emp_code || '');
                    const report_mngr = result.settlement.report_mngr || ''; 
                    setFormData({
                        comp_name: result.settlement.comp_name || '',
                        emp_code: result.settlement.emp_code || '',
                        dept_name: result.settlement.dept_name || '',
                        designation_name: result.settlement.designation_name || '',
                        purpose: result.settlement.purpose || '',
                        mode_of_travel: result.settlement.mode_of_travel || '',
                        travel_advance_amount: result.settlement.travel_advance_amount || '',
                        days: result.settlement.days || '',
                        city: result.settlement.city || '',
                        travel_advance: result.settlement.travel_advance || '',
                      //  bank_details: result.data.bank_details || '',
                        grade: result.settlement.grade || '',
                        mobile: result.settlement.mobile || '',
                        rpt_mgr_status: result.settlement.rpt_mgr_status || '',
                        report_mngr:    report_mngr,
                        manager_remarks: result.settlement.manager_remarks || '',
                        manager_approved_amount: result.settlement.manager_approved_amount || '',
                        finance_approved_amount: result.settlement.finance_approved_amount || '',
                        finance_remarks: result.settlement.finance_remarks || '',
                        account_status: result.settlement.account_status || '',
                        audit_status: result.settlement.audit_status || '',
                        audit_approved_amount: result.settlement.audit_approved_amount || '',
                        audit_remarks: result.settlement.audit_remarks || '',
                        audit_manager: result.settlement.audit_manager || '',
                        accounts_manager: result.settlement.accounts_manager || '',
                        utr: result.settlement.utr || '',
                        utr_date: result.settlement.utr_date || '',
                        created_on: result.settlement.created_on || '',
                        manager_approved_date: result.settlement.manager_approved_date || '',
                        audit_approved_date: result.settlement.audit_approved_date || '',
                        finance_approved_date : result.settlement.finance_approved_date || '',
                        
                    });
                  //  fetchReportingData(report_mngr);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };
      
        fetchProfileData();
    }, []);


    useEffect(() => {
        const fetchSettlement1 = async () => {
            try {
                const token = localStorage.getItem('authToken');
				console.log("token1er",token);
                const response = await fetch(`${BASE_URL}api/getsettlement?travel_id1=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
						 'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
				
                console.log("settl;ement1", result);

                if (result.status) {
                    
                    setRows1(result.settlement1);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchSettlement1();
    }, []);

    useEffect(() => {
        const fetchSettlement2 = async () => {
            
            try {
                const token = localStorage.getItem('authToken');
				console.log("token1a",token);
             
                const response = await fetch(`${BASE_URL}api/getsettlement?travel_id2=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
						 'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
				
                console.log("settlement2:", result);

                if (result.status) {
                    
                    setRows2(result.settlement2);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchSettlement2();
    }, []);


    

 
    const [rows, setRows] = useState([
        { id: Date.now(), date: '', time: '', tourPlace: '', travelType: '', stayType: '', perDiem: '', local: '', misc: '', totalAmount: '' },
       
    ]);
    const handleChange = (id, event) => {
        // Check if event is defined
        if (!event || !event.target) {
            console.error("Event or event.target is undefined");
            return;
        }
    
        const { name, value } = event.target;
    
        // Update rows based on id
        const updatedRows = rows.map(row => {
            if (row.id === id) {
                const newRow = { ...row, [name]: value };
                newRow.totalAmount = (parseFloat(newRow.perDiem) || 0) + (parseFloat(newRow.local) || 0) + (parseFloat(newRow.misc) || 0);
                return newRow;
            }
            return row;
        });
    
        setRows(updatedRows);
    
        // Update formData if needed
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setRows((prevRows) =>
            prevRows.map((row) => 
                row.id === id ? { ...row, [name]: value } : row
            )
        );
    };

    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), date: '', time: '', tourPlace: '',fromPlace: '', toPlace: '', travelType: '', stayType: '', perDiem: '', local: '', misc: '', totalAmount: '' }]);
    };

    const handleRemoveRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const calculateTotals = () => {
        return rows.reduce((acc, row) => {
            return {
                perDiem: acc.perDiem + (parseFloat(row.perDiem) || 0),
                local: acc.local + (parseFloat(row.local) || 0),
                misc: acc.misc + (parseFloat(row.misc) || 0),
                totalAmount: acc.totalAmount + (parseFloat(row.totalAmount) || 0)
            };
        }, { perDiem: 0, local: 0, misc: 0, totalAmount: 0 });
    };

    const totals = calculateTotals();


    const [transportRows, setTransportRows] = useState([{ id: 1, travelDate: '', origin: '', destination: '', travelAmount: '', transportMethod: '' }]);

    const handleAddTransportRow = () => {
        setTransportRows([...transportRows, { id: transportRows.length + 1, travelDate: '', origin: '', destination: '', travelAmount: '', transportMethod: '' }]);
        updateLocalConveyanceTotals();
    };

    const handleTransportChange = (index, e) => {
        const { name, value } = e.target;
        const newTransportRows = [...transportRows];
        newTransportRows[index] = { ...newTransportRows[index], [name]: value };
        setTransportRows(newTransportRows);
    };

    const handleRemoveTransportRow = (index) => {
        if (transportRows.length === 1) return; // Prevent removal if it's the only row
        const updatedRows = transportRows.filter((_, i) => i !== index).map((row, i) => ({ ...row, id: i + 1 }));
        setTransportRows(updatedRows);
        updateLocalConveyanceTotals();
    };

    

    const totalTravelAmount = transportRows.reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);
    const calculateLocalConveyanceByDate = (date) => {
        return transportRows
        .filter(row => row.travelDate === date)
        .reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);
    };

    const calculateGrandTotalLocalConveyance = () => {
        return transportRows.reduce((acc, row) => {
            return acc + (parseFloat(row.travelAmount) || 0);
        }, 0);
    };
    const calculateRowTotal = (row) => {
        const perDiem = parseFloat(row.perDiem) || 0;
        const local = calculateLocalConveyanceByDate(row.date);
        const misc = parseFloat(row.misc) || 0;
        return perDiem + local + misc;
    };
    const calculateGrandTotal = () => {
        return rows.reduce((acc, row) => {
            return acc + calculateRowTotal(row);
        }, 0);
    };
    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{fontSize:'12px'}}>
            <div className="wrapper">

                <Header />
                <Sidebar />

                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0">Tour/Travel - Settlement Form</h4>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Tour/Travel - Settlement Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="box box-info">
                                

                                <div className="card-body">
                                    <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8">
                                    <fieldset style={{border:'1px #ccc solid',padding:'10px'}}>
                                        <div className="box-body">
                                        <div className="row">

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee No
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="emp_code"
                                                            id="name"
                                                            value={formData.emp_code}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Employee No/Consultant"
                                                            readonly="true"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Name <span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="comp_name"
                                                            id="name"
                                                            value={formData.comp_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Name"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee Band<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="grade"
                                                            id="name"
                                                            value={formData.grade}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Employee Band as per Category"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Department<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="dept_name"
                                                            id="name"
                                                            value={formData.dept_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Department&Designation"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Designation <span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="designation_name"
                                                            id="name"
                                                            value={formData.designation_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Department&Designation"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Mobile No 
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="mobile"
                                                            id="name"
                                                            value={formData.mobile}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Days of travel"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
												 
                                                
												
                                            </div>
                                            <div className="row">
                                           
                                                <div className="col-md-2">
                                                <div className="form-group">
                                                    <label htmlFor="days">No of Days 
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        name="days"
                                                        id="days"
                                                        value={formData.days}
                                                        onChange={(e) => handleChange('days', e)}
                                                        className="form-control input-font"
                                                        placeholder="1,1.5,2,2.5, etc"
                                                        required
                                                      
                                                      
                                                        
                                                    />
                                                </div>
                                            </div>
                                            
                                            
                                              
                                                
                                                        <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="travelAdvance">Has advance settled <span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            name="travel_advance"
                                                            id="travelAdvance"
                                                            value={formData.travel_advance}
                                                            onChange={(e) => handleChange('travel_advance', e)}
                                                            className="form-control input-font"
                                                        >
                                                            
                                                            <option value="">Select</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Amount 
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="travel_advance_amount"
                                                            id="name"
                                                            value={formData.travel_advance_amount}
                                                            onChange={(e) => handleChange('travel_advance_amount', e)}
                                                            className="form-control input-font"
                                                            placeholder="Days of travel"
                                                            
                                                        />
                                                    </div>
                                                </div>
												<div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Purpose
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="purpose"
                                                            id="purpose"
                                                            value={formData.purpose}
                                                            onChange={(e) => handleChange('purpose', e)}
                                                            className="form-control input-font"
                                                            placeholder="Purpose"
                                                            
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Reporting Manager
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="mobile"
                                                            id="name"
                                                            value={formData.report_mngr}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Days of travel"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                
												 
                                            </div>
                                            
                                                
                                                   
                                                
                                        </div>
                                        <div className="expense-table-container">
    
    <table className="expense-table">
        <thead>
            <tr>
                <td colSpan="9"></td>
                <td></td>
            </tr>
            <tr className="header-row">
                <th>Date</th>
                <th>Time</th>
                <th>From Place</th>
                <th>To Place</th>
                <th>Hotel Booked By</th>
                <th>Hotel Amount</th>
                <th>Travel Ticket Booked By</th>
                <th>Travel Ticket Amount</th>
                <th>Travel Type</th>
             
                <th>Per Diem Allow</th>
                <th>Local Conveyance</th>
                <th>Misc Exp</th>
                <th>Total Rs.</th>
               
            </tr>
        </thead>
        <tbody>
            {rows1.map((row) => (
                <tr key={row.id}>
                    <td data-label="Date">
                        <input type="date" name="date" readonly="true" value={row.date} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" />
                    </td>
                    <td data-label="Time">
                        <input type="time" name="time" readonly="true" value={row.time} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" />
                    </td>
                    <td data-label="From Place">
                        <input type="text" name="fromPlace" readonly="true" value={row.from_city} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" placeholder="Tour Place / Places" />
                    </td>
                    <td data-label="To Place">
                        <input type="text" name="toPlace" readonly="true" value={row.to_city} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" placeholder="Tour Place / Places" />
                    </td>
                    <td data-label="Booked by">
                        <select name="hotel_booked_by" value={row.hotel_booked_by} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required disabled>
                            <option value="">Select Booked By</option>
                            <option value="Self">Self</option>
                            <option value="TravelDesk">Travel Desk</option>
                        </select>
                    </td>
                    <td data-label="Travel Type">
                        <select name="travelType" readonly="true" value={row.travelType} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" disabled>
                            <option value="">Select Travel Type</option>
                            <option value="Air">Air</option>
                            <option value="Train">Train</option>
                            <option value="Bus">Bus</option>
                        </select>
                    </td>
                    <td data-label="Hotel Amount">
                    <input type="text" name="hotel_amount" value={row.hotel_amount} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" disabled={row.hotel_booked_by === "TravelDesk"} readOnly/>
                    </td>

                    <td data-label="Booked by">
                        <select name="ticket_travel_booked_by" disabled value={row.ticket_travel_booked_by} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required>
                            <option value="">Select Booked By</option>
                            <option value="Self">Self</option>
                            <option value="TravelDesk">Travel Desk</option>
                        </select>
                    </td>
                    <td data-label="Travel Amount">
                    <input type="text" name="ticket_travel_amount" value={row.ticket_travel_amount} onChange={(e) => handleChange(row.id, e)} className="form-control input-font"  disabled={row.ticket_travel_booked_by === "TravelDesk"} readOnly/>
                    </td>
                    <td data-label="Per Diem Allow">
                        <input type="text" name="perDiem"  readonly="true"value={row.perDiem} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" placeholder="Per Diem Allowance" />
                    </td>
                    <td data-label="Local Conveyance">
                        <input type="text" name="local" value={row.local} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" placeholder="Local Conveyance" readonly="true"/>
                    </td>
                    <td data-label="Misc Exp">
                        <input type="text" name="misc" readonly="true" value={row.misc} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" placeholder="Misc Exp" />
                    </td>
                    <td data-label="Total Rs.">
                        <input type="text" name="totalAmount" value={row.totalAmount} readOnly className="form-control input-font" placeholder="Total Amount" />
                    </td>
                    <td data-label="Actions">
                        
                    </td>
                </tr>
            ))}
        </tbody>
        <tfoot>
            <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
     
                <th></th>
            </tr>
        </tfoot>
    </table>
</div>

           
            </fieldset>
            <br />
            <fieldset style={{border:'1px #ccc solid',padding:'10px'}}>
      <center><span className='btn btn-primary' style={{fontSize:'12px'}}>Details of Local Conveyance</span></center>      
     

      <div className="transport-table-container" style={{marginBottom:'20px'}}>
    
    <table className="expense-table">
        <thead>
            <tr>
                <td colSpan="8"></td>
            </tr>
            <tr className="header-row">
           
                <th style={{ textAlign: 'center' }}>Travel Date</th>
                <th style={{ textAlign: 'center' }}>Origin</th>
                <th style={{ textAlign: 'center' }}>Destination</th>
                <th style={{ textAlign: 'center' }}>Amount</th>
                <th style={{ textAlign: 'center' }}>Transport Mode</th>
                <th style={{ textAlign: 'center' }}>Receipt</th>
                
            </tr>
        </thead>
        <tbody>
            {rows2.map((row, index) => (
                <tr key={index}>
                  
                    <td data-label="Travel Date">
                        <input
                            type="date"
                            name="travelDate"
                            readonly = "true"
                            value={row.travelDate}
                            onChange={(e) => handleTransportChange(index, e)}
                            className="form-control input-font"
                        />
                    </td>
                    <td data-label="Origin">
                        <input
                            type="text"
                            name="origin"
                            readonly = "true"
                            value={row.origin}
                            onChange={(e) => handleTransportChange(index, e)}
                            className="form-control input-font"
                            placeholder="From"
                        />
                    </td>
                    <td data-label="Destination">
                        <input
                            type="text"
                            name="destination"
                            readonly = "true"
                            value={row.destination}
                            onChange={(e) => handleTransportChange(index, e)}
                            className="form-control input-font"
                            placeholder="To"
                        />
                    </td>
                    <td data-label="Amount">
                        <input
                            type="number"
                            readonly = "true"
                            name="travelAmount"
                            value={row.travelAmount}
                            onChange={(e) => handleTransportChange(index, e)}
                            className="form-control input-font"
                            placeholder="Amount"
                        />
                    </td>
                    <td data-label="Transport Mode">
                        <select
                            name="transportMethod"
                            readonly = "true"
                            value={row.transportMethod}
                            onChange={(e) => handleTransportChange(index, e)}
                            className="form-control input-font"
                           
                        >
                            <option value="">Select Mode</option>
                            <option value="bus">Bus</option>
                            <option value="auto">Auto</option>
                            <option value="taxi">Taxi</option>
                            <option value="own">Own Vehicle</option>
                        </select>
                    </td>
                    <td data-label="Receipt">
                        {row.receipt && (
                            <a href={row.receipt} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={row.receipt} // Assuming receipt holds the image URL/path
                                    alt="Receipt"
                                    style={{ width: '50px', height: '50px' }} // Adjust size as needed
                                />
                            </a>
                        )}
                    </td>
                    
                    <td data-label="Actions">
                       
                    </td>
                </tr>
            ))}
        </tbody>
        <tfoot>
            
        </tfoot>
    </table>
</div>
</fieldset>
<div class="row"> {formData.rpt_mgr_status === 'Approved' && (<div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="rpt_mgr_status">Manager Status 
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="rpt_mgr_status"
                                                                id="rpt_mgr_status"
                                                                value={formData.rpt_mgr_status}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                readonly = "true"
                                                            >
                                                                
                                                                <option value="Pending">Pending</option>
                                                                <option value="Approved">Approved</option>
                                                                <option value="Rejected">Rejected</option>
                                                            </select>
                                                        </div>
                                                    </div>)}{formData.manager_approved_amount && (<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Manager Approved Amount
														</label>
                                                        <input
                                                            type="text"
                                                            name="manager_approved_amount"
                                                            id="name"
                                                            value={formData.manager_approved_amount}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Advance Approved Amount"
                                                            readonly="true"
                                                            
                                                        />
                                                    </div>
                                                </div>)}{formData.manager_remarks && (
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="manager_remarks">Manager Remarks
														</label>
                                                       
                                                        <textarea 
                                                            id="manager_remarks"
                                                            value={formData.manager_remarks}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="manager remarks"
                                                            readonly="true"></textarea>
                                                    </div>
                                                </div>)}</div>
                                                <div class="row">{formData.audit_status === 'Approved' && (<div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="audit_status">Audit Status 
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="audit_status"
                                                                id="audit_status"
                                                                value={formData.audit_status}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                readonly = "true"
                                                            >
                                                                
                                                                <option value="Pending">Pending</option>
                                                                <option value="Approved">Approved</option>
                                                                <option value="Rejected">Rejected</option>
                                                            </select>
                                                        </div>
                                                    </div>)}{formData.audit_approved_amount && (<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="audit_approved_amount">Audit Approved Amount
														</label>
                                                        <input
                                                            type="text"
                                                            name="audit_approved_amount"
                                                            id="audit_approved_amount"
                                                            value={formData.audit_approved_amount}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Advance Approved Amount"
                                                            readonly="true"
                                                            
                                                        />
                                                    </div>
                                                </div>)}{formData.audit_remarks && (
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="audit_remarks">Audit Remarks
														</label>
                                                       
                                                        <textarea 
                                                            id="audit_remarks"
                                                            value={formData.audit_remarks}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Audit remarks"
                                                            readonly="true"></textarea>
                                                    </div>
                                                </div>)}</div>
                                                <div class="row">{formData.account_status === 'Approved' && (<div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="account_status">Finance Status 
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="account_status"
                                                                id="account_status"
                                                                value={formData.account_status}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                readonly = "true"
                                                            >
                                                                
                                                                <option value="Pending">Pending</option>
                                                                <option value="Approved">Approved</option>
                                                                <option value="Rejected">Rejected</option>
                                                            </select>
                                                        </div>
                                                    </div>)}{formData.finance_approved_amount && (<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="finance_approved_amount">Finance Approved Amount
														</label>
                                                        <input
                                                            type="text"
                                                            name="finance_approved_amount"
                                                            id="finance_approved_amount"
                                                            value={formData.finance_approved_amount}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Finance Approved Amount"
                                                            readonly="true"
                                                            
                                                        />
                                                    </div>
                                                </div>)}{formData.finance_approved_amount && (
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="finance_remarks">Finance Remarks
														</label>
                                                       
                                                        <textarea 
                                                            id="finance_remarks"
                                                            value={formData.finance_remarks}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="manager remarks"
                                                            readonly="true"></textarea>
                                                    </div>
                                                </div>)}
                                                
                                                </div>
                                                <div className="row">
                                                {formData.utr && (
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="utr">UTR
														</label>
                                                       
                                                        <input
                                                            type="text"
                                                            name="utr"
                                                            id="utr"
                                                            value={formData.utr}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="UTR"
                                                            readonly="true"
                                                            
                                                        />
                                                    </div>
                                                </div> )} {formData.utr_date && (
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="utr_date">UTR
														</label>
                                                       
                                                        <input
                                                            type="text"
                                                            name="utr_date"
                                                            id="utr_date"
                                                            value={formData.utr_date}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="UTR"
                                                            readonly="true"
                                                            
                                                        />
                                                    </div>
                                                </div>  )}
                                                </div>

            {/* <div className="col-sm-12 mt-2">
                <table className="table table-responsive table1" style={{width:'100%',marginTop:'0.1em'}}>
                    <tbody><tr>
                        <td> <b> Bank Details : </b>
                            Syndicate Bank<b> Acc. Name :</b>  Rohit R Mehta <b>  Acc. No : </b>554466778899 <b> IFSC : </b>1122333                        </td>
                    </tr>
                </tbody></table>
            </div> */}
            <div className="col-sm-12 row" style={{width: '100%'}}>
                <div style={{border: '#cccccc 1px solid'}} className="col-sm-3">
                    <b>Prepared by </b>
                    <p> {formData.emp_code}<br/> Date
                        : {formData.created_on}
                    </p>
                </div>
                <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
    {formData.rpt_mgr_status === 'Approved' && (
        <>
            <b>Approved by</b>
            <p>
                <span>
                    {formData.report_mngr} <br /> 
                    Date: {formData.manager_approved_date}
                </span>
            </p>
        </>
    )}
</div>

                <div style={{border: '#cccccc 1px solid'}} className="col-sm-3">
                {formData.account_status === 'Approved' && (
                    <>
                    <b> Verified by Audit Team</b>
                    <p>                         <span>
                    {formData.audit_manager}    <br /> Date
                    : {formData.audit_approved_date}                         
                        </span>
                                            </p>
                                            </>
                                            )}
                </div>
                <div style={{border: '#cccccc 1px solid'}} className="col-sm-3">
                {formData.audit_status === 'Approved' && (
                    <>
                    <b> Verified by Finance Teaam</b>
                    <p> {formData.accounts_manager}<br/> Date
                        : {formData.finance_approved_date}
                    </p>
                                            </>
                                            )}
                </div>
                

            </div>
            
                                        

            <div className="box-footer" style={{ textAlign: 'center' }}>
                                        {formData.rpt_mgr_status !== 'Approved' && (
                        <button type="submit" className="btn btn-success pull-right" style={{display: 'none'}}>Submit</button>
                    )}
        
    
</div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>

            </div>

            {/* Toast Container */}
            <ToastContainer />
        </div>
    );
};

export default EditSettlementForm;
