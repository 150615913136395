import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useAuth from './useAuth';
import './Manage_User.css';
import * as XLSX from 'xlsx';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import { BASE_URL } from "./Constants.js";
const Manage_Settlement = () => {
    const [EmpCode, setEmpCode] = useState('');
    const [items, setItems] = useState([]);
    const [RptMgr, setRptMgr] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState('id');
    const [sortOrder, setSortOrder] = useState('desc');
    const navigate = useNavigate();
    useAuth();
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${BASE_URL}api/profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setEmpCode(result.profile.emp_code || '');
                    setRptMgr(result.profile.report_mngr || '');
                    
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);
    useEffect(() => {
        const fetchItems = async (EmpCode) => {
            try {
                const token = localStorage.getItem('authToken');
				console.log("hi",token);
                if (!token) return;

                const response = await fetch(`${BASE_URL}api/getsettlement?emp_code=${encodeURIComponent(EmpCode)}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setItems(result.settlement);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        if (EmpCode) {
            fetchItems(EmpCode);
        }
    }, [EmpCode, navigate]); 

    useEffect(() => {
        const filtered = items.filter(item =>
            item.comp_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.SysNo.toLowerCase().includes(searchQuery.toLowerCase())
        );

        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });

        setFilteredItems(sorted);
        setCurrentPage(1); // Reset to first page when items or search query changes
    }, [searchQuery, items, sortColumn, sortOrder]);

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    const exportToExcel = () => {
		const filteredBranchNames = filteredItems.map(item => ({
        CompleteName: item.comp_name,
        Employeecode: item.emp_code,
        grade: item.grade,
        purpose: item.purpose
		}));
        const worksheet = XLSX.utils.json_to_sheet(filteredBranchNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        XLSX.writeFile(workbook, 'Advance_Details.xlsx');
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed">
            <div className="wrapper">
                <Header />
				<Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0">Tour/Travel - Settlement Form</h4>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Tour-Travel Settlement Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <section className="col-lg-12 connectedSortable">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Tour/Travel-Settlement Form</h3>
                                            <span style={{ float: 'right' }}>
                                                <Link to="/add_settlement">
                                                    <button type="button" className="btn btn-success">Add New</button>
                                                </Link>
                                                <button type="button" className="btn btn-secondary ml-2" onClick={exportToExcel}>
                                                    Export to Excel
                                                </button>
                                            </span>
                                        </div>
                                        <div className="card-body">
                                            <div id="example1_filter" style={{ float: 'right' }} className="dataTables_filter">
                                                <label>
                                                    <input
                                                        type="search"
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                        className="form-control form-control-sm"
                                                        placeholder="Search"
                                                        aria-controls="example1"
                                                    />
                                                </label>
                                            </div>
                                            
                                            <div className="form-group">
                                                <select
                                                    className="form-control custom-select-sm"
                                                    value={itemsPerPage}
                                                    onChange={handleItemsPerPageChange}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </select>
                                            </div>

                                            <table className="table table-bordered table-striped" style={{fontSize:'13px'}}>
                                                <thead>
                                                    <tr>
													<th>#
													</th>
                                                        
                                                        <th>
                                                            <button onClick={() => handleSort('emp_code')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '13px' }}>
                                                                <b>Employee Code</b> {sortColumn === 'emp_code' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <button onClick={() => handleSort('comp_name')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '13px' }}>
                                                            <b>Employee Name</b> {sortColumn === 'comp_name' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        
                                                        <th>
                                                            <button onClick={() => handleSort('grade')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '13px' }}>
                                                            <b>Grade</b> {sortColumn === 'grade' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <button onClick={() => handleSort('purpose')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '13px' }}>
                                                            <b>Purpose</b> {sortColumn === 'purpose' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                       
                                                        <th>
                                                            <button onClick={() => handleSort('travel_advance')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '13px' }}>
                                                            <b>Travel Advance</b> {sortColumn === 'travel_advance' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        
                                                      
                                                        <th>
                                                            <button onClick={() => handleSort('days')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '13px' }}>
                                                            <b>Days</b> {sortColumn === 'days' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        
                                                        <th>
                                                            <button onClick={() => handleSort('mobile')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '13px' }}>
                                                            <b>Mobile</b> {sortColumn === 'mobile' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>

                                                        

                                                        <th>
                                                            <button onClick={() => handleSort('mobile')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '13px' }}>
                                                            <b>Reporting Manager Status</b> {sortColumn === 'mobile' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>

                                                        <th>
                                                            <button onClick={() => handleSort('mobile')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '13px' }}>
                                                            <b>Audit Status</b> {sortColumn === 'mobile' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>

                                                        <th>
                                                            <button onClick={() => handleSort('mobile')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '13px' }}>
                                                            <b>Finance Status</b> {sortColumn === 'mobile' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentItems.length > 0 ? (
                                                        currentItems.map((item, index) => (
                                                            (item.emp_code === EmpCode || item.report_mngr === EmpCode) ? (
                                                            <tr key={index}>
                                                                <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                                                
                                                               
                                                                <td>{item.emp_code}</td>
                                                                <td>{item.comp_name}</td>
                                                                <td>{item.grade}</td>
                                                                <td>{item.purpose}</td>
                                                                
                                                                <td>{item.travel_advance}</td>
                                                                
                                                               
                                                                <td>{item.days}</td>
                                                               
                                                                <td>{item.mobile}</td>
                                                                <td style={{ 
                                                                        color: item.rpt_mgr_status === 'Pending' ? 'orange' : 
                                                                            item.rpt_mgr_status === 'Rejected' ? 'red' : 
                                                                            item.rpt_mgr_status === 'Approved' ? 'green' : 
                                                                            'inherit' 
                                                                    }}>
                                                                        {item.rpt_mgr_status}
                                                                    </td>
                                                                
                                                                <td style={{ 
                                                                        color: item.audit_status === 'Pending' ? 'orange' : 
                                                                            item.audit_status === 'Rejected' ? 'red' : 
                                                                            item.audit_status === 'Approved' ? 'green' : 
                                                                            'inherit' 
                                                                    }}>{item.audit_status}</td>
                                                                <td style={{ 
                                                                        color: item.account_status === 'Pending' ? 'orange' : 
                                                                            item.account_status === 'Rejected' ? 'red' : 
                                                                            item.account_status === 'Approved' ? 'green' : 
                                                                            'inherit' 
                                                                    }}>{item.account_status}</td>
                                                                <td>
                                                                    <Link to={`/edit_settlement_form/${item.id}`}>
                                                                        <button type="button" className="btn btn-success">Edit</button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            ) : null // Do not render anything if emp_code doesn't match
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="15"  style={{textAlign:'center'}}>No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <nav>
                                        <ul className="pagination" style={{ float: 'right' }}>
                                            <li className="page-item">
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                    disabled={currentPage === 1}
                                                >
                                                    Previous
                                                </button>
                                            </li>
                                            {[...Array(totalPages).keys()].map(pageNumber => (
                                                <li key={pageNumber} className={`page-item ${pageNumber + 1 === currentPage ? 'active' : ''}`}>
                                                    <button
                                                        className="page-link"
                                                        onClick={() => handlePageChange(pageNumber + 1)}
                                                    >
                                                        {pageNumber + 1}
                                                    </button>
                                                </li>
                                            ))}
                                            <li className="page-item">
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                    disabled={currentPage === totalPages}
                                                >
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="mb-3">
                                        Showing {startItem} to {endItem} of {filteredItems.length} entries
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>

               <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    );
};

export default Manage_Settlement;
