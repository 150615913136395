import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import default CSS for Toastify
import './CustomToastStyles.css'; // Import custom styles
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const EditFinanceForm = () => {
    const { id } = useParams();
    const [RptMgr, setRptMngr] = useState('');
    const formatDateTime = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
    
        // Get hours and minutes
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? String(hours).padStart(2, '0') : '12'; // Handle 0 as 12
    
        return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    };
    const [EmpCode, setEmpCode] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [items, setItems] = useState([]);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        comp_name: '',
        emp_code: '',
        grade: '',
        dept_name: '',
        designation_name: '',
        report_mngr: '',
        purpose: '',
        mode_of_travel: '',
        travel_advance: '',
        travel_advance_amount: '',
        days: '',
        city: '',
        hotel_tickets: '',
        manager_remarks: '',
        accounts_remarks: '',
        manager_approved_amount: '',
        accounts_approved_amount: '',
        account_status: '',
        utr:'',
        utr_date: '',
        manager_approved_date: '',
        accounts_approved_date: '',
        accounts_manager: '',
        created_on: '',
    });
    const [responseMessage, setResponseMessage] = useState('');
    const [isApproved, setIsApproved] = useState(false);
    const navigate = useNavigate();

    const validateFormData = () => {
        const { comp_name,emp_code,grade } = formData;
        if (!comp_name | !emp_code | !grade) {
            toast.error('All fields are required');
            return false;
        }
        return true;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        // Convert UTR input to uppercase if the name is 'utr'
        const newValue = name === 'utr' ? value.toUpperCase() : value;
    
        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue,
        }));
    };

    const handleSubmit = async (e) => {
        
        e.preventDefault();

        if (!validateFormData()) return;

        setSubmitted(true);

        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== '') {
                formDataToSubmit.append(key, formData[key]);
            }
        });
        const dataToSubmit = {
            ...formData
        };
        try {
            const token = localStorage.getItem('authToken');
console.log("check tokens",token);
            const response = await fetch(`${BASE_URL}api/updateAdvanceForm/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSubmit),
            });

            const result = await response.json();
                console.log("advanceformdata", result.data);
            if (result.status) {
                toast.success('Travel Advance Form successfully added');
                setFormData({
                    comp_name: '',
                    emp_code: ''
                });
                navigate('/finance_form');
            } else {
                toast.error(result.message || 'An error occurred while adding the travel advance form.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
				console.log("token1",token);
                const response = await fetch(`${BASE_URL}api/getadvance?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
						 'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
				
                console.log("Profile data12:", result);

                if (result.status) {
                    setEmpCode(result.advance.emp_code || '');
                    const report_mngr = result.advance.report_mngr || ''; 
                    const account_status = result.advance.account_status || '';
                    setFormData({
                        comp_name: result.advance.comp_name || '',
                        emp_code: result.advance.emp_code || '',
                        dept_name: result.advance.dept_name || '',
                        designation_name: result.advance.designation_name || '',
                        purpose: result.advance.purpose || '',
                        mode_of_travel: result.advance.mode_of_travel || '',
                        travel_advance_amount: result.advance.travel_advance_amount || '',
                        days: result.advance.days || '',
                        city: result.advance.city || '',
                        travel_advance: result.advance.travel_advance || '',
                        bank_details: result.advance.bank_details || '',
                        grade: result.advance.grade || '',
                        mobile: result.advance.mobile || '',
                        rpt_mgr_status: result.advance.rpt_mgr_status || '',
                        report_mngr:    report_mngr,
                        manager_remarks: result.advance.manager_remarks || '',
                        accounts_remarks: result.advance.accounts_remarks || '',
                        manager_approved_amount: result.advance.manager_approved_amount || '',
                        accounts_approved_amount: result.advance.accounts_approved_amount || '',
                        account_status: account_status || '',
                        utr: result.advance.utr || '',
                        utr_date: result.advance.utr_date || '',
                        manager_approved_date: result.advance.manager_approved_date || '',
                        accounts_approved_date: result.advance.accounts_approved_date || '',
                        accounts_manager: result.advance.accounts_manager || '',
                        created_on: result.advance.created_on || '',
                    });
                    setIsApproved(account_status === 'Approved');
                    //fetchReportingData(report_mngr);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };
        
        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
				console.log("token1",token);
                const response = await fetch(`${BASE_URL}api/cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
						 'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
				
                console.log("get all cities data:", result);

                if (result.status) {
                    setItems(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);

    const currentDateTime = formatDateTime(new Date());

    return (
        <div className="hold-transition sidebar-mini layout-fixed">
            <div className="wrapper">

                <Header />
                <Sidebar />

                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0">Tour/Travel Advance Finance Approval Form</h4>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Tour/Travel Advance Finance Approval Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="box box-info">
                                

                                <div className="card-body">
                                    <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8">
                                        <div className="box-body">
                                        <div className="row">
                                                
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Name <span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="comp_name"
                                                            id="name"
                                                            value={formData.comp_name}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Name"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
												 <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee No/Consultant
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="emp_code"
                                                            id="name"
                                                            value={formData.emp_code}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Employee No/Consultant"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
												 <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Department<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="dept_name"
                                                            id="name"
                                                            value={formData.dept_name}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Department&Designation"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">

                                            <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Designation <span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="designation_name"
                                                            id="name"
                                                            value={formData.designation_name}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Department&Designation"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                
                                                
												
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee Band as per Category  <span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="grade"
                                                            id="name"
                                                            value={formData.grade}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Employee Band as per Category"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
												 <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Purpose<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="purpose"
                                                            id="name"
                                                            value={formData.purpose}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Purpose"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                
                                                <div className="col-md-4">
                                                <div className="form-group">
                                                            <label htmlFor="mode_of_travel">Mode of Travel
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="mode_of_travel"
                                                                id="mode_of_travel"
                                                                value={formData.mode_of_travel}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                readonly="true"
                                                                disabled
                                                            >   
                                                                <option value="air">Air</option>
                                                                <option value="train">Train</option>
                                                                <option value="bus">Bus</option>
                                                            </select>
                                                        </div>
                                                </div>
												 <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Travel Advance requisitioned
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="travel_advance_amount"
                                                            id="name"
                                                            value={formData.travel_advance_amount}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Travel Advance requisitioned"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Bank details for transfer 
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="bank_details"
                                                            id="name"
                                                            value={formData.bank_details}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Bank details for transfer "
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                </div>
                                                    <div class="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="travelAdvance">Has earlier travel advance settled <span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            name="travel_advance"
                                                            id="travelAdvance"
                                                            value={formData.travel_advance}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            readonly="true"
                                                            disabled
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="days">Days of travel  
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        name="days"
                                                        id="days"
                                                        value={formData.days}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        placeholder="1,1.5,2,2.5,etc"
                                                        required
                                                        min="1" // Prevents entering 0 or negative numbers
                                                        step="0.1"
                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>
                                                <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="city">Place to travel  
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="city"
                                                                id="city"
                                                                value={formData.city}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                readonly="true"
                                                                disabled
                                                            >
                                                                <option value="">Select a city</option>
                                                                {items.map((city) => (
                                                                    <option key={city.id} value={city.city_name}>
                                                                        {city.city_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                               

                                                </div>
                                                <div class="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Reporting To  
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="report_mngr"
                                                            id="name"
                                                            value={formData.report_mngr}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Days of travel"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Mobile No 
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="mobile"
                                                            id="name"
                                                            value={formData.mobile}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Days of travel"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="hotel_tickets">Hotel/Tickets Booked by 
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="hotel_tickets"
                                                                id="hotel_tickets"
                                                                value={formData.hotel_tickets}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                readonly="true"
                                                                disabled
                                                            >
                                                                
                                                                <option value="self">Self</option>
                                                                <option value="travel desk">Travel Desk</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row"><div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="rpt_mgr_status">Manager Status 
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="rpt_mgr_status"
                                                                id="rpt_mgr_status"
                                                                value={formData.rpt_mgr_status}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                readonly = "true"
                                                                disabled
                                                            >
                                                                
                                                                <option value="Pending">Pending</option>
                                                                <option value="Approved">Approved</option>
                                                                <option value="Rejected">Rejected</option>
                                                            </select>
                                                        </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Manager Approved Amount
														</label>
                                                        <input
                                                            type="text"
                                                            name="manager_approved_amount"
                                                            id="name"
                                                            value={formData.manager_approved_amount}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Manager Approved Amount"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="remarks">Manager Remarks
														</label>
                                                       
                                                        <textarea name="manager_remarks"
                                                           readonly="true"
                                                            id="remarks"
                                                            value={formData.manager_remarks}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Remarks"></textarea>
                                                    </div>
                                                </div>
                                                    </div>
                                                    <div className="row">
    <div className="col-md-4">
        <div className="form-group">
            <label htmlFor="account_status">Finance Status 
                <span style={{ color: 'red' }}>*</span>
            </label>
            <select
                name="account_status"
                id="account_status"
                value={formData.account_status}
                onChange={handleChange}
                className="form-control"
                required
            >
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Rejected">Rejected</option>
            </select>
        </div>
    </div>

   
        <div className="col-md-4">
            <div className="form-group">
                <label htmlFor="name">Finance Approved Amount</label>
                <span style={{ color: 'red' }}>*</span>
                <input
                    type="number"
                    name="accounts_approved_amount"
                    id="name"
                    value={formData.accounts_approved_amount}
                    onChange={handleChange}
                    className="form-control"
                    required
                />
            </div>
        </div>
    

   
        <div className="col-md-4">
            <div className="form-group">
                <label htmlFor="remarks">Finance Remarks</label>
                <span style={{ color: 'red' }}>*</span>
                <textarea
                    name="accounts_remarks"
                    id="remarks"
                    value={formData.accounts_remarks}
                    onChange={handleChange}
                    className="form-control"
                    required
                ></textarea>
            </div>
        </div>
 
</div>
                                                    <div class="row">
                                                    <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="name">UTR
														</label><span style={{ color: 'red' }}>*</span>
                                                        <input
                                                            type="text"
                                                            name="utr"
                                                            id="name"
                                                            value={formData.utr}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="UTR"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="date">Date</label><span style={{ color: 'red' }}>*</span>
                                                    <input
                                                        type="date"
                                                        name="utr_date"
                                                        id="utr_date" // Use a unique id
                                                        value={formData.utr_date} // Bind it to your form state
                                                        onChange={handleChange} // Handle changes with the same function
                                                        className="form-control"
                                                        placeholder="Select a date" // Optional placeholder text
                                                        required
                                                    />
                                                </div>
                                            </div>
                                                    </div>
                                        </div>
                                        <br />
                                        <div className="box-footer" style={{ textAlign: 'center' }}>
                <button 
                    type="submit" 
                    className="btn btn-success pull-right" 
                    disabled={isApproved} // Disable if form has been submitted
                >
                    Submit
                </button>
            </div>
            <br />
                                        
                                    </form>
                                    
                                    
                                    {/* <div className="col-sm-12 row" style={{ width: '100%' }}>
    <table className="table table-responsive table1" style={{ width: '100%', marginTop: '0.1em' }}>
        <tbody>
            <tr>
                <td style={{ padding: '10px', backgroundColor: '#f9f9f9', display: 'flex', gap: '10px' }}>
                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '8px', flex: '1 1 25%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <b>Bank:</b> Syndicate Bank
                    </div>
                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '8px', flex: '2 1 35%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <b>Acc. Name:</b> Rohit R Mehta
                    </div>
                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '8px', flex: '1 1 20%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '120px' }}>
                        <b>Acc. No:</b> 554466778899
                    </div>
                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '8px', flex: '1 1 20%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '120px' }}>
                        <b>IFSC:</b> 1122333
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div> */}






                                    <div className="col-sm-12 row" style={{width: '100%'}}>
                <div style={{border: '#cccccc 1px solid'}} className="col-sm-3">
                    <b>Prepared by </b>
                    <p> {formData.emp_code}<br/> Date
                        : {formData.created_on}
                    </p>
                </div>
                <div style={{border: '#cccccc 1px solid'}} className="col-sm-3">
                    <b> Approved by</b>
                    <p>                         <span>
                    {formData.report_mngr}                      <br/> Date : {formData.manager_approved_date} 
                            
                        </span>
                                            </p>
                </div>
                
                

            </div>
            
                                </div>
                                
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>

            </div>

            {/* Toast Container */}
            <ToastContainer />
        </div>
    );
};

export default EditFinanceForm;
