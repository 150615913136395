import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';

const EditUser = () => {
    const { id } = useParams(); // Get the user ID from the URL
    const [formData, setFormData] = useState({
        USER_ID: '',
        USER_NAME: '',
        IS_ADMIN: '',
        DEPT: ''
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const token = localStorage.getItem('authToken');
				console.log("hi",token);
                const response = await fetch(`http://localhost/ci3/api/api/getUsers?USER_ID=${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("Fetched user data:", result); // Debugging line
                if (result.status && result.data) {
                    setFormData(result.data);
                } else {
                    setError(result.message || 'User not found');
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch user data');
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [id]);

    const validateFormData = () => {
        const { USER_ID, USER_NAME, IS_ADMIN, DEPT } = formData;
        if (!USER_ID || !USER_NAME || !IS_ADMIN || !DEPT) {
            setError('All fields are required');
            return false;
        }
        return true;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateFormData()) return;

        setError('');

        try {
            const token = localStorage.getItem('authToken');

            const response = await fetch(`http://localhost/ci3/api/api/updateUser?USER_ID=${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const result = await response.json();
            console.log(result);
            if (result.status) {
                console.log('User successfully updated:', result);
                navigate('/manage_user');
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to update user');
        }
    };

    if (loading) return <div>Loading...</div>;

    return (
        <div className="hold-transition sidebar-mini layout-fixed">
            <div className="wrapper">
                <Header />

                <Sidebar />

                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Edit User</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Edit User</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="box box-info">
                                <div className="card-header">
                                    <h3 className="card-title">Edit User</h3>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8">
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="bmid">BMID</label>
                                                        <input
                                                            type="text"
                                                            name="USER_ID"
                                                            id="bmid"
                                                            value={formData.USER_ID}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="BMID"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Name</label>
                                                        <input
                                                            type="text"
                                                            name="USER_NAME"
                                                            id="name"
                                                            value={formData.USER_NAME}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="user_type">Is Admin <span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            name="IS_ADMIN"
                                                            id="user_type"
                                                            value={formData.IS_ADMIN}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            required
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="Y">Yes</option>
                                                            <option value="N">No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="dept">Dept <span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            name="DEPT"
                                                            id="dept"
                                                            value={formData.DEPT}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            required
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="IT">IT</option>
                                                            <option value="Accounts">Accounts</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-footer">
                                            <button type="submit" className="btn btn-success pull-right">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    );
};

export default EditUser;
