import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useAuth from './useAuth';
import './Manage_User.css';
import * as XLSX from 'xlsx';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Manage_Plan = () => {
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [filterDays, setFilterDays] = useState('');
    const navigate = useNavigate();
    useAuth();

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) return;

                const response = await fetch('http://localhost/ci3/api/api/dashboard', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setItems(result.data.account);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [navigate]);

    const handleFilterChange = (e) => {
        setFilterDays(e.target.value);
    };

    useEffect(() => {
        const filtered = items.filter(item => {
            const matchesSearchQuery = item.SysField.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.network1.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.bill1.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.plan1.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.ac_num.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.id.toLowerCase().includes(searchQuery.toLowerCase());

            const today = new Date();
            const validityDate = new Date(item.validity);
            const daysDiff = Math.ceil((validityDate - today) / (1000 * 60 * 60 * 24));
            const matchesDueDateFilter = !filterDays || (daysDiff <= parseInt(filterDays, 10));

            return matchesSearchQuery && matchesDueDateFilter;
        });

        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });

        setFilteredItems(sorted);
        setCurrentPage(1);
    }, [searchQuery, items, sortColumn, sortOrder, filterDays]);

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    const exportToExcel = () => {
        const filteredNetworkNames = filteredItems.map(item => ({
            Branch: item.SysField,
            ISP: item.network1,
            Billing: item.bill1,
            Plan: item.plan1,
            Amount: item.amount,
            Accno: item.ac_num,
            Ip: item.ip
        }));
        const worksheet = XLSX.utils.json_to_sheet(filteredNetworkNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        XLSX.writeFile(workbook, 'Account_Details.xlsx');
    };

    const getValidityStyle = (validity) => {
        const today = new Date();
        const validityDate = new Date(validity);

        if (validityDate <= today) {
            return { backgroundColor: 'red', color: '#FFF' };
        } else if (validityDate <= today.setDate(today.getDate() + 7)) {
            return { backgroundColor: 'orange', color: '#FFF' };
        } else {
            return {};
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed">
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-4">
                                    <h1 className="m-0">Dashboard</h1>
                                </div>
                                <div className="col-sm-4 form-group">
                                    <select
                                        className="form-control custom-select-sm"
                                        value={filterDays}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">Filter by due date</option>
                                        <option value={2}>2 days</option>
                                        <option value={5}>5 days</option>
                                        <option value={10}>10 days</option>
                                        <option value={20}>20 days</option>
                                        <option value={30}>30 days</option>
                                        <option value={60}>60 days</option>
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                        <li className="breadcrumb-item active">Billing Details</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <section className="col-lg-12 connectedSortable">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Upcoming Bills</h3>
                                        </div>
                                        <div className="card-body">
                                            <div id="example1_filter" style={{ float: 'right' }} className="dataTables_filter">
                                                <label>
                                                    <input
                                                        type="search"
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                        className="form-control form-control-sm"
                                                        placeholder="Search"
                                                        aria-controls="example1"
                                                    />
                                                </label>
                                            </div>
                                            
                                            <div className="form-group">
                                                <select
                                                    className="form-control custom-select-sm"
                                                    value={itemsPerPage}
                                                    onChange={handleItemsPerPageChange}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </select>
                                            </div>

                                            <table className="table table-bordered table-striped" style={{fontSize:'11px'}}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>
                                                            <button onClick={() => handleSort('SysField')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                                                                Branch {sortColumn === 'SysField' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <button onClick={() => handleSort('network1')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                                                                ISP {sortColumn === 'network1' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <button onClick={() => handleSort('plan1')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                                                                Plan {sortColumn === 'plan1' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <button onClick={() => handleSort('bill1')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                                                                Billing {sortColumn === 'bill1' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <button onClick={() => handleSort('amount')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                                                                Amount (Including GST) {sortColumn === 'amount' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <button onClick={() => handleSort('ac_num')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                                                                Account no {sortColumn === 'ac_num' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <button onClick={() => handleSort('ip')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                                                                IP Address {sortColumn === 'ip' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <button onClick={() => handleSort('landno')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                                                                Landline {sortColumn === 'landno' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <button onClick={() => handleSort('validity')} className="btn btn-link" style={{ color: 'black', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                                                                Due {sortColumn === 'validity' && (sortOrder === 'asc' ? '🔼' : '🔽')}
                                                            </button>
                                                        </th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentItems.length > 0 ? (
                                                        currentItems.map((item, index) => {
                                                            const validityStyle = getValidityStyle(item.validity);
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                                                    <td>{item.SysField}</td>
                                                                    <td>{item.network1}</td>
                                                                    <td>{item.plan1}</td>
                                                                    <td>{item.bill1}</td>
                                                                    <td>{item.amount}</td>
                                                                    <td>{item.ac_num}</td>
                                                                    <td>{item.ip}</td>
                                                                    <td>{item.landno}</td>
                                                                    <td style={validityStyle}>{item.validity}</td>
                                                                    <td>
                                                                        <Link to={`/edit_account/${item.id}`}>
                                                                            <button type="button" className="btn btn-success">Edit</button>
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="11">No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <nav>
                                        <ul className="pagination" style={{ float: 'right' }}>
                                            <li className="page-item">
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                    disabled={currentPage === 1}
                                                >
                                                    Previous
                                                </button>
                                            </li>
                                            {[...Array(totalPages).keys()].map(pageNumber => (
                                                <li key={pageNumber} className={`page-item ${pageNumber + 1 === currentPage ? 'active' : ''}`}>
                                                    <button
                                                        className="page-link"
                                                        onClick={() => handlePageChange(pageNumber + 1)}
                                                    >
                                                        {pageNumber + 1}
                                                    </button>
                                                </li>
                                            ))}
                                            <li className="page-item">
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                    disabled={currentPage === totalPages}
                                                >
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="mb-3">
                                        Showing {startItem} to {endItem} of {filteredItems.length} entries
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    );
};

export default Manage_Plan;
