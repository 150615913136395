// useAuth.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = (redirectTo = '/') => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            navigate(redirectTo);
        }
    }, [navigate, redirectTo]);
};

export default useAuth;