import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import LoginForm from './LoginForm.js';
import Dashboard from './Dashboard.js';
import Dashboard1 from './Dashboard1.js';
import Home from './Home.js';
import Manage_User from './Manage_User.js';
import Add_User from './AddUser.js';
import Add_City from './AddCity.js';
import Add_Advance from './AddAdvanceForm.js';
import Add_Settlement from './AddSettlement.js';
import Add_State from './AddState.js';

import MyDataTable from './MyDataTable.js';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import EditUser from './EditUser';


import EditDashboard from './EditDashboard';
import EditAdvance from './EditAdvanceForm';
import EditManageAdvance from './EditManageAdvanceForm';
import EditSettlementForm from './EditSettlementForm';
import EditTravelDesk from './EditTravelDesk';
import EditFinanceSettlementForm from './EditFinanceSettlementForm';
import EditManagerSettlementForm from './EditManageSettlementForm.js';
import EditFinanceForm from './EditFinanceForm.js';
import EditAuditForm from './EditAuditForm.js';


import ManageSettlement from './ManageSettlement';
import ManageAdvanceAccounts from './ManageAdvanceAccounts';
import ManageEmployee from './ManageEmployee';
import ManageStates from './ManageStates';
import ManageCities from './ManageCities';
import ManageTravelDesk from './ManageTravelDesk';
import ManageHotelInfo from './ManageHotelInfo';
import ManageAdvanceForm from './ManageAdvanceForm';
import FinanceForm from './FinanceForm';
import FinanceSettlementForm from './FinanceSettlementForm';
import AuditForm from './AuditForm';
import OwnAdvanceForm from './OwnAdvanceForm';
import OwnSettlementForm from './SettlementForm.js';
import ProtectedRoute from './ProtectedRoute';
import EditCity from "./EditCity.js";
import ManageDIEM from "./ManageDIEM.js";
import AddDIEM from "./AddDIEM.js";
import EditDIEM from "./EditDIEM.js";
import EditState from "./EditState.js";
import Travel_Desk from "./Travel_Desk.js";
import Hotel_Info from "./Hotel_Info.js";

const App = () => {
	 // Depend on location to handle refresh flag correctly
  return (
  
    <Router>
      <Routes>
        //<Route path="/" element={<LoginForm />} />
		<Route path="/dashboard" element={<Dashboard />} />
		<Route path="/dashboard1" element={<Dashboard1 />} />
		<Route path="/header" element={<Header />} />
		<Route path="/home" element={<Home />} />
		
		<Route path="/manage_employee" element={<ManageEmployee />} />
		<Route path="/manage_cities" element={<ManageCities />} />
		<Route path="/manage_states" element={<ManageStates />} />
		<Route path="/add_states" element={<Add_State />} />
		<Route path="/add_city" element={<Add_City />} />
		<Route path='/add_diem' element={<AddDIEM />}/>
		<Route path="/manage_advance" element={<ManageAdvanceForm />} />
		<Route path="/own_advance" element={<OwnAdvanceForm />} />
		<Route path="/own_settlement" element={<OwnSettlementForm />} />
		
		<Route path="/manage_advance_accounts" element={<ManageAdvanceAccounts />} />
		<Route path="/manage_settlement" element={<ManageSettlement />} />
		<Route path="/finance_form" element={<FinanceForm />} />
		<Route path="/finance_settlement_form" element={<FinanceSettlementForm />} />
		<Route path="/audit_form" element={<AuditForm />} />
		
		<Route path="/edit_dashboard/:id" element={<EditDashboard />} />
		<Route path="/edit_advance_form/:id" element={<EditAdvance />} />
		<Route path="/edit_manage_advance_form/:id" element={<EditManageAdvance />} />
		<Route path="/edit_travel_desk_form/:id" element={<EditTravelDesk />} />
		<Route path="/edit_settlement_form/:id" element={<EditSettlementForm />} />
		<Route path="/edit_manager_settlement_form/:id" element={<EditManagerSettlementForm />} />
		<Route path="/edit_finance_form/:id" element={<EditFinanceForm />} />
		<Route path="/edit_audit_form/:id" element={<EditAuditForm />} />
		<Route path="/edit_finance_settlement_form/:id" element={<EditFinanceSettlementForm />} />
	   <Route	path='/edit_city/:id' element={<EditCity />}	/>
		<Route	path='/edit_state/:id'	element={<EditState />}		/>
		
		
		 <Route  path="/manage_user"   element={<ProtectedRoute element={<Manage_User />} requiredType="Y" /> } />
		<Route   path="/edit_user/:id" element={<ProtectedRoute element={<EditUser />} requiredType="Y" />  } />
		
		<Route path="/mydatatable" element={<MyDataTable />} />
		<Route path="/add_user" element={<Add_User />} />
		
		<Route path="/travel_desk" element={<Travel_Desk />} />
		<Route path="/manage_travel_desk" element={<ManageTravelDesk />} />
		<Route path="/manage_hotel_info" element={<ManageHotelInfo />} />
		<Route path="/hotel_info" element={<Hotel_Info />} />
	    
		
		
		<Route path="/add_advance" element={<Add_Advance />} />
		<Route path="/add_settlement" element={<Add_Settlement />} />
		<Route
					path='/manage_diem'
					element={<ManageDIEM />}
				/>
				<Route
					path='/edit_diem/:id'
					element={<EditDIEM />}
				/>
		
        {/* Add more routes here */}
      </Routes>
    </Router>
  );
};

export default App;


