import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';

import { BASE_URL } from './Constants.js';
import * as XLSX from 'xlsx';
import './Manage_User.css';

const ManageDIEM = () => {
	const [statelistData, setStatelistData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const [items, setItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [sortColumn, setSortColumn] = useState('id');
	const [sortOrder, setSortOrder] = useState('asc');
	const navigate = useNavigate();
	const token = localStorage.getItem('authToken') || null;
	useEffect(() => {
		const fetchItems = async () => {
			try {
				if (!token) return;

				const response = await fetch(`${BASE_URL}api/getDiemList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const result = await response.json();
				if (result.status) {
					setItems(result.data);
					setStatelistData(result.data);
				} else {
					setError(result.message);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				setError('Failed to fetch items');
			} finally {
				setLoading(false);
			}
		};

		fetchItems();
	}, [navigate]);

	useEffect(() => {
		console.log('Fetched items:', items); // Add this line to verify the structure

		const filtered = items.filter((item) => {
			const name = item.name ? item.name.toLowerCase() : '';
			const id = item.id ? item.id.toLowerCase() : '';

			return (
				name.includes(searchQuery.toLowerCase()) ||
				id.includes(searchQuery.toLowerCase())
			);
		});

		const sorted = filtered.sort((a, b) => {
			if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
			if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
			return 0;
		});

		setFilteredItems(sorted);
		setCurrentPage(1); // Reset to first page when items or search query changes
	}, [searchQuery, items, sortColumn, sortOrder]);

	const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

	const startItem = indexOfFirstItem + 1;
	const endItem = Math.min(indexOfLastItem, filteredItems.length);

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

	const handleSort = (column) => {
		if (sortColumn === column) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		} else {
			setSortColumn(column);
			setSortOrder('asc');
		}
	};

	const exportToExcel = () => {
		const filteredStateNames = filteredItems.map((item) => ({
			Id: item.state_id,
			Name: item.state_name,
		}));
		const worksheet = XLSX.utils.json_to_sheet(filteredStateNames);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'States');
		XLSX.writeFile(workbook, 'State_Details.xlsx');
	};

	return (
		<>
			<div className='hold-transition sidebar-mini layout-fixed'>
				<div className='wrapper'>
					<Header />
					<Sidebar />
					<div className='content-wrapper'>
						<div className='content-header'>
							<div className='container-fluid'>
								<div className='row mb-2'>
									<div className='col-sm-6'>
										<h1 className='m-0'>DIEM Details</h1>
									</div>
									<div className='col-sm-6'>
										<ol className='breadcrumb float-sm-right'>
											<li className='breadcrumb-item'>
												<a href='#'>Home</a>
											</li>
											<li className='breadcrumb-item'>
												<a href='#'>User</a>
											</li>
											<li className='breadcrumb-item active'>DIEM Details</li>
										</ol>
									</div>
								</div>
							</div>
						</div>
						<section className='content'>
							<div className='container-fluid'>
								<div className='row'>
									<section className='col-lg-12 connectedSortable'>
										<div className='card'>
											<div className='card-header'>
												<h3 className='card-title'>DIEM Classification</h3>
												<span style={{ float: 'right' }}>
													<Link to='/add_diem'>
														<button type='button' className='btn btn-success'>
															Add New
														</button>
													</Link>
													<button
														type='button'
														className='btn btn-secondary ml-2'
														onClick={exportToExcel}
													>
														Export to Excel
													</button>
												</span>
											</div>
											<div className='card-body'>
												{loading ? (
													<p>Loading...</p>
												) : error ? (
													<p>Error: {error}</p>
												) : (
													<>
														<div
															id='example1_filter'
															style={{ float: 'right' }}
															className='dataTables_filter'
														>
															<label>
																<input
																	type='search'
																	value={searchQuery}
																	onChange={handleSearchChange}
																	className='form-control form-control-sm'
																	placeholder='Search'
																	aria-controls='example1'
																/>
															</label>
														</div>

														<div className='form-group'>
															<select
																className='form-control custom-select-sm'
																value={itemsPerPage}
																onChange={handleItemsPerPageChange}
															>
																<option value={10}>10</option>
																<option value={50}>50</option>
																<option value={100}>100</option>
															</select>
														</div>

														<table className='table table-bordered table-striped'>
															<thead>
																<tr>
																	<th>ID</th>
																	<th>
																		<button
																			onClick={() => handleSort('name')}
																			className='btn btn-link'
																			style={{
																				color: 'black',
																				backgroundColor: 'transparent',
																				border: 'none',
																				cursor: 'pointer',
																			}}
																		>
																			City Class{' '}
																			{sortColumn === 'name' &&
																				(sortOrder === 'asc' ? '🔼' : '🔽')}
																		</button>
																	</th>
																	<th>
																		<button
																			onClick={() => handleSort('name')}
																			className='btn btn-link'
																			style={{
																				color: 'black',
																				backgroundColor: 'transparent',
																				border: 'none',
																				cursor: 'pointer',
																			}}
																		>
																			Band{' '}
																			{sortColumn === 'name' &&
																				(sortOrder === 'asc' ? '🔼' : '🔽')}
																		</button>
																	</th>
																	<th>DIEM</th>
																	<th>HOTEL</th>
																	<th>Action</th>
																</tr>
															</thead>
															<tbody>
																{currentItems.length > 0 ? (
																	currentItems.map((item, index) => (
																		<tr key={index}>
																			<td>
																				{index +
																					1 +
																					(currentPage - 1) * itemsPerPage}
																			</td>

																			<td>{item.city_class}</td>
																			<td>{item.grade}</td>
																			<td>{item.diem}</td>
																			<td>{item.hotel}</td>
																			<td>
																				<Link
																					to={`/edit_diem/${item.d_id}`}
																					state={{
																						city_class: item.city_class,
																						grade: item.grade,
																					}}
																				>
																					<button
																						type='button'
																						className='btn btn-success btn-sm'
																					>
																						Edit
																					</button>
																				</Link>
																			</td>
																		</tr>
																	))
																) : (
																	<tr>
																		<td colSpan='3'>No data available</td>
																	</tr>
																)}
															</tbody>
														</table>
													</>
												)}
											</div>
										</div>
										<nav>
											<ul className='pagination' style={{ float: 'right' }}>
												<li className='page-item'>
													<button
														className='page-link'
														onClick={() => handlePageChange(currentPage - 1)}
														disabled={currentPage === 1}
													>
														Previous
													</button>
												</li>
												{[...Array(totalPages).keys()].map((pageNumber) => (
													<li
														key={pageNumber}
														className={`page-item ${
															pageNumber + 1 === currentPage ? 'active' : ''
														}`}
													>
														<button
															className='page-link'
															onClick={() => handlePageChange(pageNumber + 1)}
														>
															{pageNumber + 1}
														</button>
													</li>
												))}
												<li className='page-item'>
													<button
														className='page-link'
														onClick={() => handlePageChange(currentPage + 1)}
														disabled={currentPage === totalPages}
													>
														Next
													</button>
												</li>
											</ul>
										</nav>
										<div className='mb-3'>
											Showing {startItem} to {endItem} of {filteredItems.length}{' '}
											entries
										</div>
									</section>
								</div>
							</div>
						</section>
					</div>

					<Footer />

					<aside className='control-sidebar control-sidebar-dark'></aside>
				</div>
			</div>
		</>
	);
};

export default ManageDIEM;
