import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './3logo.jpg';
import { BASE_URL } from "./Constants.js";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const LoginForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        emp_code: '',
        password: ''
    });
    const [responseMessage, setResponseMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            console.log("Attempting to log in...");
    
            const response = await fetch(`https://travel.vdcapp.in/backend/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(formData),
            });
    
            if (!response.ok) {
                const errorResult = await response.json();
                throw new Error(errorResult.message || 'Login failed');
            }
    
            const result = await response.json();
            console.log("Login response:", result);
    
            if (result.status) {
                const token = result.token;
                localStorage.setItem('authToken', token); // Save token to localStorage
                
                console.log("Bearer Token for Profile API:", token); // Log the token
    
                console.log("Fetching user profile...");
    
                const profileResponse = await fetch(`https://travel.vdcapp.in/backend/api/profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Ensure correct format
                    },
                });
    
                // Log the headers being sent
                console.log("Request Headers for Profile API:");
                console.log({
                    'Authorization': `Bearer ${token}`,
                });
    
                // Check if the response is okay
                if (!profileResponse.ok) {
                    const profileErrorResult = await profileResponse.json();
                    throw new Error(profileErrorResult.message || 'Error fetching profile');
                }
    
                const profileResult = await profileResponse.json();
                console.log("Profile response:", profileResult);
    
                if (profileResult.status) {
                    localStorage.setItem('userProfile', JSON.stringify(profileResult.profile)); // Save profile data to localStorage
                    navigate('/dashboard'); // Redirect to the dashboard
                } else {
                    setResponseMessage('Error fetching profile: ' + profileResult.message);
                }
            } else {
                setResponseMessage('Error: ' + result.message);
            }
        } catch (error) {
            setResponseMessage('Error: ' + error.message);
        }
    };

    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="login-logo">
                    <img src={logo} alt="Travel App" style={{ width: '100%' }} />
                    <br />
                    <b>Travel App</b> 
                </div>

                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Please Login To Continue..</p>

                        <form onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="BMID"
                                    name="emp_code"
                                    value={formData.emp_code}
                                    required
                                    onChange={handleChange}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-user"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    required
                                    onChange={handleChange}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <button type="submit" className="btn btn-success btn-block btn-flat">Sign In</button>
                                </div>
                            </div>
                        </form>
                        {responseMessage && <p className="text-danger">{responseMessage}</p>}
                    </div>
                </div>
                {/* Version and Developer Info */}
                <div className="text-center mt-3">
                    <p>Version: 1.0.0</p>
                    <p>Developed by: Vijaya Diagnostic Center</p>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
