import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useAuth from './useAuth';
import './Manage_User.css';
import * as XLSX from 'xlsx';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Manage_Plan = () => {
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [filterDays, setFilterDays] = useState('');
    const navigate = useNavigate();
    useAuth();

    

    const handleFilterChange = (e) => {
        setFilterDays(e.target.value);
    };

    useEffect(() => {
        const filtered = items.filter(item => {
            const matchesSearchQuery = item.SysField.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.network1.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.bill1.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.plan1.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.ac_num.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.id.toLowerCase().includes(searchQuery.toLowerCase());

            const today = new Date();
            const validityDate = new Date(item.validity);
            const daysDiff = Math.ceil((validityDate - today) / (1000 * 60 * 60 * 24));
            const matchesDueDateFilter = !filterDays || (daysDiff <= parseInt(filterDays, 10));

            return matchesSearchQuery && matchesDueDateFilter;
        });

        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });

        setFilteredItems(sorted);
        setCurrentPage(1);
    }, [searchQuery, items, sortColumn, sortOrder, filterDays]);

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    const exportToExcel = () => {
        const filteredNetworkNames = filteredItems.map(item => ({
            Branch: item.SysField,
            ISP: item.network1,
            Billing: item.bill1,
            Plan: item.plan1,
            Amount: item.amount,
            Accno: item.ac_num,
            Ip: item.ip
        }));
        const worksheet = XLSX.utils.json_to_sheet(filteredNetworkNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        XLSX.writeFile(workbook, 'Account_Details.xlsx');
    };

    const getValidityStyle = (validity) => {
        const today = new Date();
        const validityDate = new Date(validity);

        if (validityDate <= today) {
            return { backgroundColor: 'red', color: '#FFF' };
        } else if (validityDate <= today.setDate(today.getDate() + 7)) {
            return { backgroundColor: 'orange', color: '#FFF' };
        } else {
            return {};
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed">
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-4">
                                    <h1 className="m-0">Welcome To Travel Portal</h1>
                                </div>
                                <div className="col-sm-4 form-group">
                                    
                                </div>
                                <div className="col-sm-4">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">Home</li>
                                     
                                        <li className="breadcrumb-item active">Dashboard</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <section className="col-lg-12 connectedSortable">
                                    
                                    
                                </section>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    );
};

export default Manage_Plan;
