import React, { useState, useEffect } from 'react';
import useAuth from './useAuth';
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import { ToastContainer, toast } from 'react-toastify';

const Dashboard = () => {
    useAuth();
    return (
	
        <div className="hold-transition sidebar-mini layout-fixed">
            <div className="wrapper">

                <Header />
				<Sidebar />

<div>
            
            {/* Your dashboard content */}
            <ToastContainer /> {/* Include ToastContainer here */}
        </div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Dashboard</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                        <li className="breadcrumb-item active">Dashboard v1</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-3 col-6">
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <h3>150</h3>
                                            <p>New Orders</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-bag"></i>
                                        </div>
                                        <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <h3>53<sup style={{ fontSize: '20px' }}>%</sup></h3>
                                            <p>Bounce Rate</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-stats-bars"></i>
                                        </div>
                                        <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="small-box bg-warning">
                                        <div className="inner">
                                            <h3>44</h3>
                                            <p>User Registrations</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-person-add"></i>
                                        </div>
                                        <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="small-box bg-danger">
                                        <div className="inner">
                                            <h3>65</h3>
                                            <p>Unique Visitors</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-pie-graph"></i>
                                        </div>
                                        <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <section className="col-lg-12 connectedSortable">
                                    <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                                        <div className="card-header">
                                            <h3 className="card-title">Upcoming Bills</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
											<table id="example1" className="table table-bordered table-striped">
                  <thead>
				   
                   <tr>
                    <th>#</th>
                    <th>BM ID</th>
                    <th>Name</th>
                   
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>1</td>
                    <td>Internet
                      Explorer 4.0
                    </td>
                    <td>Win 95+</td>
                   
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Internet
                      Explorer 5.0
                    </td>
                    <td>Win 95+</td>
                    
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Internet
                      Explorer 5.5
                    </td>
                    <td>Win 95+</td>
                    
                  </tr>
                  </tbody>
                 
                </table>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark"></aside>
            </div>
        </div>
    );
};

export default Dashboard;
