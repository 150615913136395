import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL } from "./Constants.js";

const Header = () => {
    const [formData, setFormData] = useState({
        emp_code: ''
    });
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`https://travel.vdcapp.in/backend/api/logout`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const result = await response.json();
            if (result.status) {
                localStorage.removeItem('authToken');
                localStorage.removeItem('expirationTime'); // Clear expiration time
                navigate('/');
            }
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);
                const response = await fetch(`https://travel.vdcapp.in/backend/api/profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("Profile data1:", result);

                if (result.status) {
                    setFormData({
                        emp_code: result.profile.emp_code || '',
                        mail_id: result.profile.mail_id || '',
                        password: '' // Do not display the password
                    });

                    // Store expiration time in localStorage
                    const expirationTime = Date.now() + 3600 * 1000; // 1 hour from now
                    localStorage.setItem('expirationTime', expirationTime);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        const checkTokenExpiration = () => {
            const expirationTime = localStorage.getItem('expirationTime');
            if (expirationTime && Date.now() > expirationTime) {
                handleLogout();
            }
        };

        fetchProfileData();
        checkTokenExpiration();

        // Set a timer for 1 hour (3600000 ms) to automatically log out
        const timer = setTimeout(() => {
            handleLogout();
        }, 3600000); // 1 hour

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [navigate]);

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{ backgroundColor: 'rgb(64 68 72)' }}>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <Link to="#" className="nav-link" data-widget="pushmenu" role="button">
                        <i className="fas fa-bars" style={{ color: 'white' }}></i>
                    </Link>
                </li>
            </ul>

            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown user user-menu">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <img
                            src="https://cdn-icons-png.flaticon.com/512/9385/9385289.png"
                            className="user-image"
                            alt="User Image"
                        />
                        <span className="hidden-xs" style={{ color: 'white' }}>{formData.emp_code || ''}</span>
                    </a>
                    <ul className="dropdown-menu" style={{ margin: '0px -160px' }}>
                        <li className="user-header">
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/9385/9385289.png"
                                className="img-circle"
                                alt="User Image"
                            />
                            <p>Admin</p>
                        </li>
                        <li className="user-footer">
                            <div className="pull-right">
                                <button className="btn btn-default btn-flat" onClick={handleLogout}>
                                    Sign out
                                </button>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
};

export default Header;
