import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CustomToastStyles.css";
import Header from "./Header.js";
import Sidebar from "./Sidebar.js";
import Footer from "./Footer.js";
import Statelist from "./Statelist.js";

import { token } from "./Constants.js";
import { BASE_URL } from "./Constants.js";

const EditCity = () => {
	const { id } = useParams();
	const [formData, setFormData] = useState({
		city_name: "",
		city_class: "",
		state_id: "",
		id: id, // Use the id from params
	});
	const [statelistData, setStatelistData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const navigate = useNavigate();

	const validateFormData = () => {
		const { city_name, city_class, state_id, id } = formData;
		if (!city_name || !city_class || !state_id || !id) {
			toast.error("All fields are required");
			return false;
		}
		return true;
	};

	const handleChange = e => {
		const { name, value, type, files } = e.target;
		setFormData(prevState => ({
			...prevState,
			[name]: type === "file" ? files[0] : value,
		}));
	};

	const handleFetchedStateListData = useCallback(data => {
		if (data.length === 0) {
			setError("No data available.");
		} else {
			setStatelistData(data);
			setError("");
		}
		setLoading(false);
	}, []);

	const handleSubmit = async e => {
		e.preventDefault();

		if (!validateFormData()) return;

		const urlEncodedData = new URLSearchParams();
		Object.keys(formData).forEach(key => {
			urlEncodedData.append(key, formData[key]);
		});

		try {
			const response = await fetch(`${BASE_URL}api/updateCityType`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/x-www-form-urlencoded", // Explicit URL-encoded type
				},
				body: urlEncodedData.toString(),
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.status} ${response.statusText}`);
			}

			const result = await response.json();

			if (result.status) {
				toast.success("City successfully updated");
				navigate("/manage_cities");
			} else {
				toast.error(
					result.message || "An error occurred while updating the City.",
				);
			}
		} catch (error) {
			console.error("Submission error:", error);
			toast.error(`Failed to submit form: ${error.message}`);
		}
	};

	useEffect(() => {
		const fetchCity = async () => {
			try {
				const formData = new URLSearchParams();
				formData.append("id", id); // Fetch city by id

				const response = await fetch(`${BASE_URL}api/getCityType`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: formData.toString(), // Send URL-encoded form data
				});

				const result = await response.json();

				// Populate form data, including id
				setFormData({
					city_name: result.data.city_name,
					city_class: result.data.city_class,
					state_id: result.data.state_id,
					id: id, // Ensure the id is set here
				});
			} catch (error) {
				console.error("Fetch error:", error);
				setError("Failed to get city details");
			}
			setLoading(false);
		};

		fetchCity(); // Fetch the city details
	}, [id]);

	return (
		<>
			<Statelist onStatesFetched={handleFetchedStateListData} />
			<div className='hold-transition sidebar-mini layout-fixed'>
				<div className='wrapper'>
					<Header />
					<Sidebar />

					<div className='content-wrapper'>
						<div className='content-header'>
							<div className='container-fluid'>
								<div className='row mb-2'>
									<div className='col-sm-6'>
										<h1 className='m-0'>Edit City</h1>
									</div>
									<div className='col-sm-6'>
										<ol className='breadcrumb float-sm-right'>
											<li className='breadcrumb-item'>
												<a href='#'>Home</a>
											</li>
											<li className='breadcrumb-item active'>Edit City</li>
										</ol>
									</div>
								</div>
							</div>
						</div>

						<section className='col-lg-12'>
							<div
								className='card'
								style={{ borderTop: "3px solid #00c0ef" }}>
								<div className='box box-info'>
									<div className='card-header'>
										<h3 className='card-title'>Edit City</h3>
									</div>

									<div className='card-body'>
										<form
											onSubmit={handleSubmit}
											method='post'
											acceptCharset='utf-8'>
											<div className='box-body'>
												<div className='row'>
													<div className='col-md-4'>
														<div className='form-group'>
															<label htmlFor='city_name'>
																Name<span style={{ color: "red" }}>*</span>
															</label>
															<input
																type='text'
																name='city_name'
																value={formData.city_name}
																onChange={handleChange}
																className='form-control'
																placeholder='Name'
															/>
														</div>
													</div>

													<div className='col-md-4'>
														<div className='form-group'>
															<label htmlFor='city_class'>
																City Class
																<span style={{ color: "red" }}>*</span>
															</label>
															<select
																name='city_class'
																value={formData.city_class}
																onChange={handleChange}
																className='form-control'>
																<option value=''> Select Class </option>
																<option value='A'> A </option>
																<option value='B'> B </option>
																<option value='C'> C </option>
																<option value='D'> D </option>
															</select>
														</div>
													</div>

													<div className='col-md-4'>
														<div className='form-group'>
															<label htmlFor='state_id'>
																State<span style={{ color: "red" }}>*</span>
															</label>
															<select
																name='state_id'
																value={formData.state_id}
																onChange={handleChange}
																className='form-control'>
																<option value=''> Select State </option>
																{statelistData.map((stt, index) => (
																	<option
																		key={index}
																		value={stt.id}>
																		{stt.name}
																	</option>
																))}
															</select>
														</div>
													</div>
												</div>
											</div>

											<div className='box-footer'>
												<input
													type='hidden'
													name='id'
													value={formData.id}
												/>
												<button
													type='submit'
													className='btn btn-success pull-right'>
													Submit
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</section>
					</div>

					<Footer />

					<aside className='control-sidebar control-sidebar-dark'></aside>
				</div>

				<ToastContainer />
			</div>
		</>
	);
};

export default EditCity;
