import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import useAuth from './useAuth';
import 'react-toastify/dist/ReactToastify.css'; // Import default CSS for Toastify
import './CustomToastStyles.css'; // Import custom styles
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const AddAdvanceForm = () => {
    const [RptMgr, setRptMngr] = useState('');
    useAuth();
    const formatDateTime = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
    
        // Get hours and minutes
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? String(hours).padStart(2, '0') : '12'; // Handle 0 as 12
    
        return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    };
    const [EmpCode, setEmpCode] = useState('');
    const [items, setItems] = useState([]);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        comp_name: '',
        emp_code: '',
        grade: '',
        dept_name: '',
        designation_name: '',
        report_mngr: '',
        password: '', // Do not display the password
        purpose: '',
        mode_of_travel: '',
        travel_advance: '',
        travel_advance_amount: '',
        days: '',
        city: '',
        hotel_tickets: '',
        travel_tickets: '',
        to_city: '',
        
    });
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();

    const validateFormData = () => {
        const { comp_name,emp_code,grade } = formData;
        if (!comp_name | !emp_code | !grade) {
            toast.error('All fields are required');
            return false;
        }
        return true;
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        const newValue = name === 'bank_details' ? value.toUpperCase() : value;
        
        setFormData(prevState => ({
            ...prevState,
            [name]: newValue,
        }));
    };
    const validateDays = () => {
        const { days } = formData;
        console.log("Days input:", days); // Log the input value

        const regex = /^(1|1\.5|2|2\.5|3|3\.5|4)$/; // Matches 1, 2, ..., 10, 1.5, 2.5, ..., 10.5 etc.
        if (days && !regex.test(days)) {
            toast.error('Please enter a valid number (1, 1.5, 2, 2.5, etc.)');
            return false;
        } else {
            setError('');
            return true;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateFormData()) return;
        if (!validateDays()) {
            return; // Prevent submission if validation fails
        }

        if (formData.hotel_tickets === 'TravelDesk' && formData.travel_tickets === 'TravelDesk') {
            toast.error('Cannot Submit if both are TravelDesk.');
            return; // Prevent submission if both are TravelDesk
        }
        
        const formDataToSubmit = new FormData();
        
        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== '') {
                formDataToSubmit.append(key, formData[key]);
            }
        });

   

        try {
            const token = localStorage.getItem('authToken');

            const response = await fetch(`${BASE_URL}api/postadvance`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formDataToSubmit
            });

            const result = await response.json();
                console.log("advanceformdata", result.data);
            if (result.status) {
                toast.success('Travel Advance Form successfully added');
                setFormData({
                    comp_name: '',
                    emp_code: ''
                });
                navigate('/own_advance');
            } else {
                toast.error(result.message || 'An error occurred while adding the travel advance form.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
				console.log("token1",token);
                const response = await fetch(`${BASE_URL}api/profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
						 'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
				
                console.log("Profile data1:", result);

                if (result.status) {
                    setEmpCode(result.profile.emp_code || '');
                    const report_mngr = result.profile.report_mngr || ''; 
                    setFormData({
                        comp_name: result.profile.comp_name || '',
                        emp_code: result.profile.emp_code || '',
                        dept_name: result.profile.dept_name || '',
                        designation_name: result.profile.designation_name || '',
                        grade: result.profile.grade || '',
                        travel_advance: result.profile.travel_advance || '',
                        travel_advance_amount: result.profile.travel_advance_amount || '',
                        bank_details: result.profile.bank_details || '',
                        days: result.profile.days || '',
                        city: result.profile.city || '',
                        purpose: result.profile.purpose || '',
                        mode_of_travel: result.profile.mode_of_travel || '',
                        mobile: result.profile.mobile || '',
                        report_mngr:    report_mngr,
                        password: '', // Do not display the password
                        hotel_tickets: result.profile.hotel_tickets || '',
                        travel_tickets: result.profile.travel_tickets || '',
                       // to_city: result.profile.to_city || '',
                    });
                  
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };
      
        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
				console.log("token1",token);
                const response = await fetch(`${BASE_URL}api/cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
						 'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
				
                console.log("get all cities data:", result);

                if (result.status) {
                    setItems(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);

    const currentDateTime = formatDateTime(new Date());

    return (
        <div className="hold-transition sidebar-mini layout-fixed">
            <div className="wrapper">

                <Header />
                <Sidebar />

                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Tour/Travel Advance Form</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Tour/Travel Advance Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="box box-info">
                                

                                <div className="card-body">
                                    <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8">
                                        <div className="box-body">
                                        <div className="row">
                                                
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Name <span style={{ color: 'red', fontSize: '11px' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="comp_name"
                                                            id="name"
                                                            value={formData.comp_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Name"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
												 <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Employee No/Consultant
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="emp_code"
                                                            id="name"
                                                            value={formData.emp_code}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Employee No/Consultant"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
												 <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Department<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="dept_name"
                                                            id="name"
                                                            value={formData.dept_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Department&Designation"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">

                                            <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Designation <span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="designation_name"
                                                            id="name"
                                                            value={formData.designation_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Department&Designation"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                
                                                
												
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Employee Band as per Category  <span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="grade"
                                                            id="name"
                                                            value={formData.grade}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Employee Band as per Category"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
												 <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Purpose<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="purpose"
                                                            id="name"
                                                            value={formData.purpose}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                
                                                <div className="col-md-4">
                                                <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="mode_of_travel">Mode of Travel
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select required
                                                                name="mode_of_travel"
                                                                id="mode_of_travel"
                                                                value={formData.mode_of_travel}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                            >
                                                                <option value="">Select</option>
                                                                <option value='Air'>Air </option>
                                                                <option value='Train'>Train </option>
                                                                <option value='Bus'>Bus</option>
                                                                <option value='Local_Taxi'>
                                                                                Local_Taxi
                                                                </option>
                                                                <option value='Local_Auto'>
                                                                                Local_Auto
                                                                </option>
                                                                <option value='Own_Bike'>Own_Bike</option>
                                                                <option value='Own_Car'>Own_Car </option>
                                                                
                                                            </select>
                                                        </div>
                                                </div>
												 <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Travel Advance requisitioned
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="number"
                                                            name="travel_advance_amount"
                                                            id="name"
                                                            value={formData.travel_advance_amount}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                          
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Bank details for transfer 
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="bank_details"
                                                            id="name"
                                                            value={formData.bank_details}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                        
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                </div>
                                                    <div class="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="travelAdvance">Has earlier travel advance settled <span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            name="travel_advance"
                                                            id="travelAdvance"
                                                            value={formData.travel_advance}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            required
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="days">Days of travel  
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input 
                                                        type="number"
                                                        name="days"
                                                        id="days"
                                                        value={formData.days}
                                                        onChange={handleChange}
                                                        onBlur={validateDays}
                                                        className="form-control input-font"
                                                        placeholder="1,1.5,2,2.5,etc"
                                                        required
                                                        min="1" // Prevents entering 0 or negative numbers
                                                        step="0.1"
                                                        
                                                    />
                                                </div>
                                                {error && <p className="text-danger">{error}</p>}
                                            </div>
                                             {/* Display error message */}
                                                <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="city">Place to travel  
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="city"
                                                                id="city"
                                                                value={formData.to_city}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            >
                                                                <option value="">Select a city</option>
                                                                {items.map((city) => (
                                                                    <option key={city.id} value={city.city_name}>
                                                                        {city.city_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                               

                                                </div>
                                                <div class="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Reporting To  
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="report_mngr"
                                                            id="name"
                                                            value={formData.report_mngr}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Days of travel"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Mobile No 
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                        
                                                            type="text"
                                                            name="mobile"
                                                            id="name"
                                                            value={formData.mobile}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Days of travel"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="hotel_tickets">Hotel Booked by 
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                required
                                                                name="hotel_tickets"
                                                                id="hotel_tickets"
                                                                value={formData.hotel_tickets}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                            >
                                                                
                                                                <option value="Self">Self</option>
                                                                <option value="TravelDesk">Travel Desk</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div class="row">
                                                <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="travel_tickets">Travel Ticket Booked by 
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                required
                                                                name="travel_tickets"
                                                                id="travel_tickets"
                                                                value={formData.travel_tickets}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                            >
                                                                
                                                                <option value="Self">Self</option>
                                                                <option value="TravelDesk">Travel Desk</option>
                                                            </select>
                                                        </div>
                                                    </div></div>
                                        </div>

                                        <div className="box-footer" style={{textAlign:'center'}}>
                                            <button type="submit" className="btn btn-success pull-right">Submit</button>
                                        </div>
                                        
                                    </form>
                                    <br />
                                    
                                    {/* <div className="col-sm-12 row" style={{ width: '100%' }}>
    <table className="table table-responsive table1" style={{ width: '100%', marginTop: '0.1em' }}>
        <tbody>
            <tr>
                <td style={{ fontSize: '11px', padding: '10px', backgroundColor: '#f9f9f9', display: 'flex', gap: '10px' }}>
                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '8px', flex: '1 1 25%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <b>Bank:</b> Syndicate Bank
                    </div>
                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '8px', flex: '2 1 35%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <b>Acc. Name:</b> Rohit R Mehta
                    </div>
                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '8px', flex: '1 1 20%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '120px' }}>
                        <b>Acc. No:</b> 554466778899
                    </div>
                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '8px', flex: '1 1 20%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '120px' }}>
                        <b>IFSC:</b> 1122333
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div> */}






                                    <div className="col-sm-12 row" style={{fontSize: '12px',width: '100%'}}>
                <div style={{border: '#cccccc 1px solid'}} className="col-sm-3">
                    <b>Prepared by </b>
                    <p> {formData.emp_code}<br/> Date
                        : {currentDateTime}
                    </p>
                </div>
                

            </div>
            
                                </div>
                                
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>

            </div>

            {/* Toast Container */}
            <ToastContainer />
        </div>
    );
};

export default AddAdvanceForm;
