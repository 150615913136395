import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import default CSS for Toastify
import './CustomToastStyles.css'; // Import custom styles
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import './AddSettlement.css';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const AddSettlementForm = () => {
    const [localConveyanceTotals, setLocalConveyanceTotals] = useState({});
    const [items, setItems] = useState([]);
    const [Grade, setGrade] = useState('');
    const [CityClass, setCityClass] = useState('');
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        emp_code: '',
        grade: '',
        dept_name: '',
        designation_name: '',
        report_mngr: '',
        password: '', // Do not display the password,
        travel_from_date: '', // Ensure this is initialized
    travel_to_date: '',   // Ensure this is initialized
    days: ''
    });
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();

    const validateFormData = () => {
        const { emp_code } = formData;
        if (!emp_code) {
            toast.error('All fields are required');
            return false;
        }
        return true;
    };

   
    const updateLocalConveyanceTotals = () => {
        const totals = {};
        transportRows.forEach((row) => {
            if (row.travelDate) {
                totals[row.travelDate] = (totals[row.travelDate] || 0) + (parseFloat(row.travelAmount) || 0);
            }
        });
        setLocalConveyanceTotals(totals);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        for (const row of transportRows) {
            if (row.travelAmount > 300 && !row.receipt) {
                toast.error(`Receipt is mandatory for travel amount greater than 300 for the entry from ${row.origin} to ${row.destination}.`);
                return; // Stop submission
            }
        }
    
        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== '') {
                formDataToSubmit.append(key, formData[key]);
            }
        });
    
        rows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                formDataToSubmit.append(`rows[${index}][${key}]`, row[key]);
            });
        });
    
        transportRows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                formDataToSubmit.append(`transportRows[${index}][${key}]`, row[key]);
            });
        });
    
        // Calculate grand totals
        const grandTotalPerDiem = rows.reduce((total, row) => total + (parseFloat(row.perDiem) || 0), 0);
        const grandTotalLocal = rows.reduce((total, row) => total + (parseFloat(row.local) || 0), 0);
        const grandTotalMisc = rows.reduce((total, row) => total + (parseFloat(row.misc) || 0), 0);
        const grandTotalAmount = rows.reduce((total, row) => total + (parseFloat(row.totalAmount) || 0), 0);
    
        formDataToSubmit.append('grandTotalPerDiem', grandTotalPerDiem);
        formDataToSubmit.append('grandTotalLocal', grandTotalLocal);
        formDataToSubmit.append('grandTotalMisc', grandTotalMisc);
        formDataToSubmit.append('grandTotalAmount', grandTotalAmount);
    
        // Debugging logs
        const formDataObject = {};
        formDataToSubmit.forEach((value, key) => {
            console.log(`${key}: ${value}`);
            if (formDataObject[key]) {
                // If the key exists, convert it to an array if it's not already
                if (!Array.isArray(formDataObject[key])) {
                    formDataObject[key] = [formDataObject[key]];
                }
                // Push the new value to the array
                formDataObject[key].push(value);
            } else {
                formDataObject[key] = value;
            }
        });
        console.log('Form Data to Submit:', JSON.stringify(formDataObject, null, 2));

        try {
            const token = localStorage.getItem('authToken');
            console.log("checktoken", token);
    
            const response = await fetch(`https://travel.vdcapp.in/backend/api/postSettlement`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formDataToSubmit
            });
    
            const result = await response.json();
            console.log("Response Data:", result);
    
            if (result.status) {
                toast.success('Travel Advance Form successfully added');
                setFormData({ emp_code: '' }); // Reset other fields as necessary
                navigate('/own_settlement');
            } else {
                toast.error(result.message || 'An error occurred while adding the travel advance form.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };

    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
				console.log("token1",token);
                const response = await fetch(`https://travel.vdcapp.in/backend/api/cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
						 'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
				
                console.log("get all cities data:", result);

                if (result.status) {
                    setItems(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);
   
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
				console.log("token1",token);
                const response = await fetch(`https://travel.vdcapp.in/backend/api/profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
						 'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
				
                console.log("Profile data1:", result);

                if (result.status) {
                    const report_mngr = result.profile.report_mngr || ''; 
                    const emp_code = result.profile.emp_code || ''; 
                    setGrade(result.profile.grade || '');
                    setFormData(prevData => ({
                        ...prevData,
                        comp_name: result.profile.comp_name || '',
                        emp_code: result.profile.emp_code || '',
                        dept_name: result.profile.dept_name || '',
                        designation_name: result.profile.designation_name || '',
                        grade: result.profile.grade || '',
                        report_mngr: result.profile.report_mngr || '',
                        mobile: result.profile.mobile || '', // Do not display the password
                    }));
                    //fetchReportingData(emp_code);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };
      
        fetchProfileData();
    }, []);

    

 
    const [rows, setRows] = useState([
        { id: Date.now(), date: '', time: '', tourPlace: '',fromPlace: '', toPlace: '',travelType: '',hotel_booked_by: '',hotel_amount: '',	ticket_travel_booked_by: '',ticket_travel_amount: '', stayType: '', perDiem: '', local: '', misc: '', totalAmount: '' },
       
    ]);
    const handleChange = (id, event) => {
        // Check if event is defined
        if (!event || !event.target) {
            console.error("Event or event.target is undefined");
            return;
        }
    
        const { name, value, type, files } = event.target;
        console.log(`Changing ${name} for row ${id} to ${value}`);
    
        // Update rows based on id
        const updatedRows = rows.map(row => {
            if (row.id === id) {
                const newRow = { ...row, [name]: value };
    
             
    
                // Update total amount based on current values
                newRow.totalAmount = (parseFloat(newRow.perDiem) || 0) +
                                     (parseFloat(newRow.local) || 0) +
                                     (parseFloat(newRow.misc) || 0);
                return newRow;
            }
            return row;
        });
    
        // Set the updated rows state
        setRows(updatedRows);
    
        // Handle specific booked_by logic
        // if (name === 'booked_by') {
        //     if (value === 'Self') {
        //         fetchPerDiemData(id); // Call the async function for Self
        //     } else if (value === 'TravelDesk') {
        //         fetchPerDiemData1(id); // Call the async function for TravelDesk
        //     }
        // }
    
        // Optional: Update form data state if necessary
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? files[0] : value
        }));
    };
    

    const fetchCityClassData = async (value) => {
   
        try {
            const token = localStorage.getItem('authToken');
       
            const gradeFirstChar = Grade.charAt(0);
            const response = await fetch(`https://travel.vdcapp.in/backend/api/getCityClass?city_name=${encodeURIComponent(value)}`, {
                method: 'GET', // or 'POST' depending on your API
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                    'Content-Type': 'application/json', // Set the content type if needed
                }
            }); // Replace with your actual API endpoint
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            const city_class = result.data.city_class;
            const hotelValue = result.data.hotel;
    
            console.log('city class Value:', city_class); 
            
            setCityClass(city_class);
          
            // Update the specific row with the fetched Per Diem value
            
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };
        // Function to fetch Per Diem data from API
const fetchPerDiemData = async (id) => {
   
    try {
        const token = localStorage.getItem('authToken');
        console.log("cheeeks",token);
        const gradeFirstChar = Grade.charAt(0);
        const response = await fetch(`https://travel.vdcapp.in/backend/api/getDiem?grade=${encodeURIComponent(gradeFirstChar)}&city_class=${encodeURIComponent(CityClass)}`, {
            method: 'GET', // or 'POST' depending on your API
            headers: {
                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                'Content-Type': 'application/json', // Set the content type if needed
            }
        }); // Replace with your actual API endpoint
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        const hotelValue = result.data.hotel;

        console.log('Hotel Value:', hotelValue); 
      
        // Update the specific row with the fetched Per Diem value
        setRows(prevRows =>
            prevRows.map(row =>
                row.id === id ? { ...row, perDiem: hotelValue } : row // Update perDiem based on your API response
            )
        );
    } catch (error) {
        console.error('Fetch error:', error);
    }
};

// const fetchPerDiemData1 = async (id) => {
   
//     try {
//         const token = localStorage.getItem('authToken');
//         console.log("cheeeks",token);
//         const gradeFirstChar = Grade.charAt(0);
//         const response = await fetch(`https://travel.vdcapp.in/backend/api/getDiem?grade=${encodeURIComponent(gradeFirstChar)}&city_class=${encodeURIComponent(CityClass)}`, {
//             method: 'GET', // or 'POST' depending on your API
//             headers: {
//                 'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
//                 'Content-Type': 'application/json', // Set the content type if needed
//             }
//         }); // Replace with your actual API endpoint
//         if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         const result = await response.json();
//         const diem = result.data.diem;

//         console.log('Hotel Value:', diem); 
      
//         // Update the specific row with the fetched Per Diem value
//         setRows(prevRows =>
//             prevRows.map(row =>
//                 row.id === id ? { ...row, perDiem: diem } : row // Update perDiem based on your API response
//             )
//         );
//     } catch (error) {
//         console.error('Fetch error:', error);
//     }
// };



    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), date: '', time: '', tourPlace: '',fromPlace: '', toPlace: '', travelType: '',hotel_booked_by: '',hotel_amount: '',	ticket_travel_booked_by: '',ticket_travel_amount: '',stayType: '', perDiem: '', local: '', misc: '', totalAmount: '' }]);
    };

    const handleRemoveRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const calculateTotals = () => {
        return rows.reduce((acc, row) => {
            return {
                perDiem: acc.perDiem + (parseFloat(row.perDiem) || 0),
                local: acc.local + (parseFloat(row.local) || 0),
                misc: acc.misc + (parseFloat(row.misc) || 0),
                totalAmount: acc.totalAmount + (parseFloat(row.totalAmount) || 0)
            };
        }, { perDiem: 0, local: 0, misc: 0, totalAmount: 0 });
    };

    const totals = calculateTotals();


    const [transportRows, setTransportRows] = useState([{ id: 1, travelDate: '', origin: '', destination: '', travelAmount: '', transportMethod: '', receipt: '' }]);

    const handleAddTransportRow = () => {
        setTransportRows([...transportRows, { id: transportRows.length + 1, travelDate: '', origin: '', destination: '', travelAmount: '', transportMethod: '', receipt: '' }]);
        updateLocalConveyanceTotals();
    };

    const handleTransportChange = (index, e) => {
        const { name, value, type, files } = e.target;
        const newTransportRows = [...transportRows];
    
        // Update the transport row with either file or other data
        if (type === 'file' && files.length > 0) {
            newTransportRows[index] = { ...newTransportRows[index], [name]: files[0] }; // Store the file object
        } else {
            newTransportRows[index] = { ...newTransportRows[index], [name]: value }; // Update other values
        }
    
        setTransportRows(newTransportRows);
    
        // Recalculate local conveyance for each row in 'rows'
        // const updatedRows = rows.map(row => ({
        //     ...row,
        //     local: calculateLocalConveyanceByDate(row.date), // Recalculate local amount
        //     totalAmount: calculateRowTotal({ ...row, local: calculateLocalConveyanceByDate(row.date) }) // Update total
        // }));
        //setRows(updatedRows);
    };

    const handleRemoveTransportRow = (index) => {
        if (transportRows.length === 1) return; // Prevent removal if it's the only row
        const updatedRows = transportRows.filter((_, i) => i !== index).map((row, i) => ({ ...row, id: i + 1 }));
        setTransportRows(updatedRows);
        updateLocalConveyanceTotals();
    };

    

    const totalTravelAmount = transportRows.reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);
    // const calculateLocalConveyanceByDate = (date) => {
    //     const total = transportRows
    //         .filter(row => row.travelDate === date)
    //         .reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);
    //     console.log(`Local conveyance for ${date}: ${total}`); // Debugging line
    //     return total;
    // };

    const calculateGrandTotalLocalConveyance = () => {
        return transportRows.reduce((acc, row) => {
            return acc + (parseFloat(row.travelAmount) || 0);
        }, 0);
    };
    // const calculateRowTotal = (row) => {
    //     const perDiem = parseFloat(row.perDiem) || 0;
    //     const local = calculateLocalConveyanceByDate(row.date);
    //     const misc = parseFloat(row.misc) || 0;
    //     return perDiem + local + misc;
    // };
    // const calculateGrandTotal = () => {
    //     return rows.reduce((acc, row) => {
    //         return acc + calculateRowTotal(row);
    //     }, 0);
    // };
    const handleLocalChange = (id, e) => {
    const { value } = e.target;
    setRows(prevRows =>
        prevRows.map(row =>
            row.id === id ? { ...row, local: value } : row
        )
    );
};
    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{fontSize:'12px'}}>
            <div className="wrapper">

                <Header />
                <Sidebar />

                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Tour/Travel Settlement Form</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Tour/Travel Settlement Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="box box-info">
                                

                                <div className="card-body">
                                    <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8" enctype="multipart/form-data">
                                    <fieldset style={{border:'1px #ccc solid',padding:'10px'}}>
                                        <div className="box-body">
                                        <div className="row">

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee No
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="emp_code"
                                                            id="name"
                                                            value={formData.emp_code}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Employee No/Consultant"
                                                            readonly="true"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Name <span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="comp_name"
                                                            id="name"
                                                            value={formData.comp_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Name"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee Band<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="grade"
                                                            id="name"
                                                            value={formData.grade}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                          
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Department<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="dept_name"
                                                            id="name"
                                                            value={formData.dept_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Designation <span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="designation_name"
                                                            id="name"
                                                            value={formData.designation_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                           
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Mobile No 
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="mobile"
                                                            id="name"
                                                            value={formData.mobile}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
												 
                                                
												
                                            </div>
                                            <div className="row">
                                           
                                                <div className="col-md-2">
                                                <div className="form-group">
                                                    <label htmlFor="days">No of Days 
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        name="days"
                                                        id="days"
                                                        value={formData.days}
                                                        onChange={(e) => handleChange('days', e)}
                                                        className="form-control input-font"
                                                        placeholder="1,1.5,2,2.5, etc"
                                                        required
                                                      
                                                      
                                                        
                                                    />
                                                </div>
                                            </div>
                                            
                                            
                                              
                                                
                                                        <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="travelAdvance">Has advance settled <span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            name="travel_advance"
                                                            id="travelAdvance"
                                                            value={formData.travel_advance}
                                                            onChange={(e) => handleChange('travel_advance', e)}
                                                            className="form-control input-font"
                                                            required
                                                        >
                                                            
                                                            <option value="">Select</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Amount 
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="number"
                                                            name="travel_advance_amount"
                                                            id="name"
                                                            value={formData.travel_advance_amount}
                                                            onChange={(e) => handleChange('travel_advance_amount', e)}
                                                            className="form-control input-font"
                                                           
                                                            required
                                                            
                                                        />
                                                    </div>
                                                </div>
												<div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Purpose
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="purpose"
                                                            id="purpose"
                                                            value={formData.purpose}
                                                            onChange={(e) => handleChange('purpose', e)}
                                                            className="form-control input-font"
                                                          
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Reporting Manager
														<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="report_mngr"
                                                            id="name"
                                                            value={formData.report_mngr}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                
												 
                                            </div>
                                            
                                                
                                                   
                                                
                                        </div>
                                        <div className="expense-table-container" style={{textAlign:'right'}}>
    <button type="button" onClick={handleAddRow} className="btn btn-primary">+</button>
    <table className="expense-table">
        <thead>
            <tr>
                <td colSpan="9"></td>
                <td></td>
            </tr>
            <tr className="header-row">
                <th>Date</th>
                <th>Time</th>
                <th>Travel Type</th>
                <th>From Place</th>
                <th>To Place</th>
                
                <th>Hotel Booked By</th>
                <th>Hotel Amount</th>
                <th>Travel Ticket Booked By</th>
                <th>Travel Ticket Amount</th>
                <th>Per Diem Allow</th>
                <th>Local Conveyance</th>
                <th>Misc Exp</th>
                <th>Total Rs.</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {rows.map((row) => (
                <tr key={row.id}>
                    <td data-label="Date">
                        <input
                            type="date"
                            name="date"
                            value={row.date}
                            onChange={(e) => handleChange(row.id, e)}
                            className="form-control input-font"
                            max={new Date().toISOString().split("T")[0]} // Sets the max to today
                            required
                        />
                    </td>
                    <td data-label="Time">
                        <input type="time" name="time" value={row.time} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required/>
                    </td>
                    <td data-label="Travel Type">
                        <select name="travelType" value={row.travelType} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required>
                            <option value="">Select Travel Type</option>
                            <option value="Air">Air</option>
                            <option value="Train">Train</option>
                            <option value="Bus">Bus</option>
                            <option value="Car">Own Vehicle-Car</option>
                            <option value="Bike">Own Vehicle-Bike</option>
                        </select>
                    </td>
                    <td data-label="From Place">
                       
                        <select name="fromPlace" value={row.fromPlace} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required>
                    <option value="">Select a city</option>
                                                                {items.map((city) => (
                                                                    <option key={city.id} value={city.city_name}>
                                                                        {city.city_name}
                                                                    </option>
                                                                ))}
                        </select>
                    </td>
                    <td data-label="To Place">
                    <select name="toPlace" value={row.toPlace} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required>
                    <option value="">Select a city</option>
                                                                {items.map((city) => (
                                                                    <option key={city.id} value={city.city_name}>
                                                                        {city.city_name}
                                                                    </option>
                                                                ))}
                        </select>
                        
                    </td>
                   
                    <td data-label="Booked by">
                        <select name="hotel_booked_by" value={row.hotel_booked_by} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required>
                            <option value="">Select Booked By</option>
                            <option value="Self">Self</option>
                            <option value="TravelDesk">Travel Desk</option>
                        </select>
                    </td>
                    <td data-label="Hotel Amount">
                    <input type="number" name="hotel_amount" value={row.hotel_amount} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" disabled={row.hotel_booked_by === "TravelDesk"}/>
                    </td>

                    <td data-label="Booked by">
                        <select name="ticket_travel_booked_by" value={row.ticket_travel_booked_by} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required>
                            <option value="">Select Booked By</option>
                            <option value="Self">Self</option>
                            <option value="TravelDesk">Travel Desk</option>
                        </select>
                    </td>
                    <td data-label="Travel Amount">
                    <input type="number" name="ticket_travel_amount" value={row.ticket_travel_amount} onChange={(e) => handleChange(row.id, e)} className="form-control input-font"  disabled={row.ticket_travel_booked_by === "TravelDesk"} />
                    </td>
                    
                    <td data-label="Per Diem Allow">
                        <input type="number" name="perDiem" value={row.perDiem} onChange={(e) => handleChange(row.id, e)} className="form-control input-font"  required/>
                    </td>
                    <td data-label="Local Conveyance">
                        <input type="number" name="local" value={row.local} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" />
                    </td>
                    <td data-label="Misc Exp">
                        <input type="number" name="misc" value={row.misc} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" />
                    </td>
                    <td data-label="Total Rs.">
                        <input type="number" name="totalAmount" value={row.total}  className="form-control input-font"  />
                    </td>
                    <td data-label="Actions">
                        <button type="button" onClick={() => handleRemoveRow(row.id)} className="btn btn-danger">×</button>
                    </td>
                </tr>
            ))}
        </tbody>
        <tfoot>
            <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
             
                <th>Grand Total</th>
                <th style={{ textAlign: 'center' }}></th>
                <th style={{ textAlign: 'center' }}></th>
                <th style={{ textAlign: 'center' }}></th>
                <th style={{ textAlign: 'center' }}></th>
                <th></th>
            </tr>
        </tfoot>
    </table>
</div>

           
            </fieldset>
            <br />
            <fieldset style={{border:'1px #ccc solid',padding:'10px'}}>
      <center><span className='btn btn-primary' style={{fontSize:'12px'}}>Details of Local Conveyance</span></center>      
     

      <div className="transport-table-container" style={{marginBottom:'20px',textAlign:'right'}}>
    <button type="button" onClick={handleAddTransportRow} className="btn btn-primary">+</button>
    <table className="expense-table">
        <thead>
            <tr>
                <td colSpan="8"></td>
            </tr>
            <tr className="header-row">
           
                <th style={{ textAlign: 'center' }}>Travel Date</th>
                <th style={{ textAlign: 'center' }}>Origin</th>
                <th style={{ textAlign: 'center' }}>Destination</th>
                <th style={{ textAlign: 'center' }}>Amount</th>
                <th style={{ textAlign: 'center' }}>Transport Mode</th>
                <th style={{ textAlign: 'center' }}>Receipt/Bill</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {transportRows.map((row, index) => (
                <tr key={index}>
                  
                    <td data-label="Travel Date">
                        <input
                            type="date"
                            name="travelDate"
                            value={row.travelDate}
                            onChange={(e) => handleTransportChange(index, e)}
                            className="form-control input-font"
                            max={new Date().toISOString().split("T")[0]}
                            required
                        />
                    </td>
                    <td data-label="Origin">
                        <input
                            type="text"
                            name="origin"
                            value={row.origin}
                            onChange={(e) => handleTransportChange(index, e)}
                            className="form-control input-font"
                            placeholder="From"
                            required
                        />
                    </td>
                    <td data-label="Destination">
                        <input
                            type="text"
                            name="destination"
                            value={row.destination}
                            onChange={(e) => handleTransportChange(index, e)}
                            className="form-control input-font"
                            placeholder="To"
                            required
                        />
                    </td>
                    <td data-label="Amount">
                        <input
                            type="number"
                            name="travelAmount"
                            value={row.travelAmount}
                            onChange={(e) => handleTransportChange(index, e)}
                            className="form-control input-font"
                            
                            required
                        />
                    </td>
                    <td data-label="Transport Mode">
                        <select
                            name="transportMethod"
                            value={row.transportMethod}
                            onChange={(e) => handleTransportChange(index, e)}
                            className="form-control input-font"
                            required
                        >
                            <option value="">Select Mode</option>
                            <option value="bus">Bus</option>
                            <option value="auto">Auto</option>
                            <option value="taxi">Taxi</option>
                            <option value="Car">Own Vehicle-Car</option>
                            <option value="Bike">Own Vehicle-Bike</option>
                        </select>
                    </td>
                    <td data-label="Receipt/Bill">
                <input
                    type="file"
                    name="receipt"
                    accept="image/*,application/pdf"
                    onChange={(e) => handleTransportChange(index, e)}
                    className="form-control input-font"
                />
                {row.travelAmount > 300 && !row.receipt && (
                    <span style={{ color: 'red' }}>Receipt is mandatory for amounts greater than 300.</span>
                )}
            </td>
                    <td data-label="Actions">
                        <button type="button" onClick={() => handleRemoveTransportRow(index)} className="btn btn-danger">×</button>
                    </td>
                </tr>
            ))}
        </tbody>
        <tfoot>
            <tr>
                <td colSpan="4" style={{ textAlign: 'right' }}>Total Amount:</td>
                <td>{totalTravelAmount}</td>
                <td colSpan="3"></td>
            </tr>
        </tfoot>
    </table>
</div>
</fieldset>


            {/* <div className="col-sm-12 mt-2">
                <table className="table table-responsive table1" style={{width:'100%',marginTop:'0.1em'}}>
                    <tbody><tr>
                        <td> <b> Bank Details : </b>
                            Syndicate Bank<b> Acc. Name :</b>  Rohit R Mehta <b>  Acc. No : </b>554466778899 <b> IFSC : </b>1122333                        </td>
                    </tr>
                </tbody></table>
            </div> */}
            
            
                                        

                                        <div className="box-footer">
                                            <br />
                                            <center><button type="submit" className="btn btn-success pull-right">Submit</button></center>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>

            </div>

            {/* Toast Container */}
            <ToastContainer />
        </div>
    );
};

export default AddSettlementForm;
