// ProtectedRoute.js
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const ProtectedRoute = ({ element, requiredType }) => {
    const location = useLocation();
    const token = localStorage.getItem('authToken');
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
    const userDept = userProfile.DEPT || '';
	const userType = userProfile.IS_ADMIN || '';

    const isAuthenticated = !!token;
    const hasAccess = isAuthenticated && (requiredType ? userType === requiredType : true);

   

    if (!hasAccess) {
        return <Navigate to="/dashboard" state={{ from: location }} replace />;
    }

    return element;
};

export default ProtectedRoute;