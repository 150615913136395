import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';

const EditDashboard = () => {
    const { id } = useParams(); // Get the user ID from the URL
    const [formData, setFormData] = useState({
        name: ''
    });
    const [error, setError] = useState('');
	const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
	const [branches, setBranches] = useState([]);
	const [plans, setPlans] = useState([]);
	const [networks, setNetworks] = useState([]);
    const navigate = useNavigate();
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
useEffect(() => {
        const fetchItems = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) return;
				const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;



                   const response = await fetch(`${API_BASE_URL}getBills`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setItems(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [navigate]);
	useEffect(() => {
        const fetchBranches = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) return;
				const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;



                   const response = await fetch(`${API_BASE_URL}getBranches`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setBranches(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        fetchBranches();
    }, [navigate]);
	 useEffect(() => {
        const fetchPlans = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) return;

                const response = await fetch(`${API_BASE_URL}getPlans`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setPlans(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        fetchPlans();
    }, [navigate]);
	useEffect(() => {
        const fetchNetworks = async () => {
            try {
                const token = localStorage.getItem('authToken');
				
                if (!token) return;

                const response = await fetch(`${API_BASE_URL}getNetworks`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setNetworks(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        fetchNetworks();
    }, [navigate]);
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}dashboardById?id=${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("Fetched plan data:", result); // Debugging line
                if (result.status && result.data) {
                    setFormData(result.data);
                } else {
                    setError(result.message || 'Plan not found');
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch plan data');
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [id]);

    const validateFormData = () => {
        const { ac_num } = formData;
        if (!ac_num) {
            setError('All fields are required');
            return false;
        }
        return true;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

// State to keep track of the group account selection
    const [isGroupAccount, setIsGroupAccount] = useState('');
	const [selectedBill, setSelectedBill] = useState('');
	const [isBill, setIsBill] = useState('');
	const [isBranch, setIsBranch] = useState('');
	const [isNetwork, setIsNetwork] = useState('');
	const [isPlan, setIsPlan] = useState('');
	useEffect(() => {
    // Assuming `formData.bill` contains the selected bill's id
    setSelectedBill(formData.bill); // Initialize with the id from formData
}, [formData.bill]);

    // Handler for dropdown change
    const handleDropdownChange = (event) => {
        setIsGroupAccount(event.target.value);
    };
	
	const handleBillChange = (event) => {
        setSelectedBill(event.target.value);
    };
	const handleBranchChange = (event) => {
        setIsBranch(event.target.value);
    };
	const handleNetworkChange = (event) => {
        setIsNetwork(event.target.value);
    };
	const handlePlanChange = (event) => {
        setIsPlan(event.target.value);
    };
    const handleSubmit = async (e) => {
		
        e.preventDefault();

        if (!validateFormData()) return;

        setError('');
		
		 const formDataToSubmit = new FormData();
        Object.keys(formData).forEach(key => {
        if (!['bill1', 'plan1', 'network1', 'SysField'].includes(key) && formData[key] !== '') {
            formDataToSubmit.append(key, formData[key]);
        }
    });
        formDataToSubmit.append('bill', selectedBill);
        formDataToSubmit.append('branch', isBranch);
        formDataToSubmit.append('plan', isPlan);
        formDataToSubmit.append('network', isNetwork);

        try {
			
            const token = localStorage.getItem('authToken');
console.log("checktoken",token);
            const response = await fetch(`${API_BASE_URL}updateAccount?id=${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const result = await response.json();
            console.log("chisk",result);
            if (result.status) {
                console.log('Account successfully updated:', result);
                navigate('/manage_accounts');
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to update Plan');
        }
    };

    if (loading) return <div>Loading...</div>;

    return (
        <div className="hold-transition sidebar-mini layout-fixed">
            <div className="wrapper">
                <Header />

                <Sidebar />

                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Edit Account</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Edit Account</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="box box-info">
                                <div className="card-header">
                                    <h3 className="card-title">Edit Account</h3>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8">
									
                                        <div className="box-body">
                                           <div className="row">
                                                
                                                
												
												<div className="col-md-4">
													<div className="form-group">
														<label for="exampleInputPassword1">Billing<span style={{color:'red'}}>*</span></label>
														<select
														  className="form-control select2"
														  name="bill"
														  
														  id="bill"
														  value={selectedBill}
														  onChange={handleBillChange}
														>
														
														  <option value="">Select</option>
														  {items.map((item) => (
															<option key={item.id} value={item.id}>
															  {item.name}
															</option>
														  ))}
														</select>
													</div>
												</div>
												
												<div className="col-md-4">
													<div className="form-group">
														<label for="exampleInputPassword1">Branches<span style={{color:'red'}}>*</span></label>
														<select
														  className="form-control select2"
														  name="branch"
														  
														  id="bill"
														  value={isBranch}
														  onChange={handleBranchChange}
														>
														<option value="">{formData.SysField}</option>
														  <option value="">Select</option>
														  {branches.map((branch) => (
															<option key={branch.SysNo} value={branch.SysNo}>
															  {branch.SysField}
															</option>
														  ))}
														</select>
													</div>
												</div>
												
												<div className="col-md-4">
													<div className="form-group">
														<label for="exampleInputPassword1">Plans<span style={{color:'red'}}>*</span></label>
														<select
                        className="form-control select2"
                        name="plan"
                        
                        id="plan" // Ensure this matches the `htmlFor` attribute in the `label`
                        value={isPlan}
                        onChange={handlePlanChange}
                    >
					<option value="">{formData.plan1}</option>
                        <option value="">Select</option>
                        {plans.map((plan) => (
                            <option key={plan.id} value={plan.id}>
                                {plan.name}
                            </option>
                        ))}
                    </select>
													</div>
												</div>
                                            </div>
											<div className="row">
                                                
                                                
												
												<div className="col-md-4">
													<div className="form-group">
														<label for="exampleInputPassword1">Networks<span style={{color:'red'}}>*</span></label>
														<select
														  className="form-control select2"
														  name="network"
														  
														  id="bill"
														  value={isNetwork}
														  onChange={handleNetworkChange}
														>
														  <option value="">{formData.network1}</option>
														  <option value="">Select</option>
														  {networks.map((network) => (
															<option key={network.id} value={network.id}>
															  {network.name}
															</option>
														  ))}
														</select>
													</div>
												</div>
												
												
												<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label for="name">Account Number<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="ac_num"
                                                            id="name"
                                                            value={formData.ac_num}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Name"
                                                        />
                                                    </div>
                                                </div>
												
												<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label for="name">Ip Address<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="ip"
                                                            id="name"
                                                            value={formData.ip}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Name"
                                                        />
                                                    </div>
                                                </div>
												
                                            </div>
											<div className="row">
                                                
                                                
												
												<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label for="name">Subnet<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="subnet"
                                                            id="name"
                                                            value={formData.subnet }
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Name"
                                                        />
                                                    </div>
                                                </div>
												
												
												<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label for="name">Gateway<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="dns1"
                                                            id="name"
                                                            value={formData.dns1 }
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Name"
                                                        />
                                                    </div>
                                                </div>
												
												<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label for="name">DNS1<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="dns2"
                                                            id="name"
                                                            value={formData.dns2 }
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Name"
                                                        />
                                                    </div>
                                                </div>
												
                                            </div>
											<div className="row">
                                                
                                                
												
												<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label for="name">Landline<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="landno"
                                                            id="name"
                                                            value={formData.landno}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Name"
                                                        />
                                                    </div>
                                                </div>
												
												
												
												
												<div className="col-md-4">
												<div className="form-group">
												<label for="exampleInputPassword1">Due<span style={{color:'red'}}>*</span></label>
												<input type="date" id="billingStartDate" name="validity" value={formData.validity}
                                                            onChange={handleChange} className="form-control" required="" placeholder="DNS2"/>
												</div>
												</div>
												
												<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label for="name">Amount<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="amount"
                                                            id="name"
                                                            value={formData.amount}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Name"
															
                                                        />
                                                    </div>
                                                </div>
												</div>
												
												 
                                        </div>
                                        <div className="box-footer">
                                            <button type="submit" className="btn btn-success pull-right">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    );
};

export default EditDashboard;
